/**
 * Simple helpers for dealing with iCalander (ics).
 */

import ICAL from 'ical.js';
import { UserProfileRow } from 'server/types';
import { EventRow, EventTypeRow } from 'server/types/cal-schema';

type ICSUser = {
  name: string;
  email: string;
};

type ICSStatus = 'CONFIRMED' | 'CANCELLED';

type ICSOpts = {
  uid: string;
  summary: string;
  dtstart: Date;
  dtend: Date;
  host: ICSUser;
  attendee: ICSUser;
  sequence: number;
  status: ICSStatus;
  prodid: string;
  location: string;
  altrep: string;
  description: string;
};

/**
 * Create an ICS User entry.
 * If partstat is undefined, we generate a simple organizer entry:
 *  CN=Chris Davies:mailto:chris@example.com
 *
 * Otherwise, it's an attendee entry:
 * CUTYPE=INDIVIDUAL;ROLE=REQ-PARTICIPANT;PARTSTAT=ACCEPTED;CN=Gonna
 *  Cancel:mailto:chris+gonnacancel@exmple.com
 */
function icalUser(componentName: string, u: ICSUser & { partstat?: 'ACCEPTED' | 'NEEDS-ACTION' }) {
  const result = new ICAL.Property(componentName);
  result.setParameter('cn', u.name);
  result.setValue('mailto:' + u.email);
  if (u.partstat) {
    result.setParameter('cutype', 'INDIVIDUAL');
    result.setParameter('role', 'REQ-PARTICIPANT');
    result.setParameter('partstat', u.partstat);
  }
  return result;
}

export function toIcs(opts: ICSOpts) {
  const vevent = new ICAL.Component('VEVENT');
  const comp = new ICAL.Component(['VCALENDAR', [], []]);
  comp.addPropertyWithValue('method', opts.status === 'CANCELLED' ? 'CANCEL' : 'REQUEST');
  comp.addPropertyWithValue('prodid', opts.prodid);
  comp.addPropertyWithValue('version', '2.0');
  comp.addPropertyWithValue('calscale', 'GREGORIAN');

  comp.addSubcomponent(vevent);

  vevent.addPropertyWithValue('uid', opts.uid);
  vevent.addPropertyWithValue('sequence', opts.sequence || 0);
  vevent.addPropertyWithValue('dtstart', ICAL.Time.fromJSDate(opts.dtstart, true));
  vevent.addPropertyWithValue('dtend', ICAL.Time.fromJSDate(opts.dtend, true));
  vevent.addPropertyWithValue('summary', opts.summary);
  vevent.addPropertyWithValue('status', opts.status);
  vevent.addProperty(
    icalUser('organizer', { name: 'Ruzuku Calendar', email: 'noreply+ruzcal@ruzuku.com' }),
  );
  vevent.addProperty(icalUser('attendee', { ...opts.host, partstat: 'ACCEPTED' }));
  vevent.addProperty(icalUser('attendee', { ...opts.attendee, partstat: 'ACCEPTED' }));

  if (opts.location) {
    const location = new ICAL.Property('location');
    location.setParameter('altrep', opts.altrep);
    location.setValue(opts.location);
    vevent.addProperty(location);
  }
  if (opts.description) {
    vevent.addPropertyWithValue('description', opts.description);
  }
  return comp.toString();
}

/**
 * Convert a Ruzcal event to an ics blob.
 */
export function eventToIcal({
  event,
  eventType,
  host,
  attendee,
  eventURL,
  isCanceled,
}: {
  event: Pick<EventRow, 'id' | 'start' | 'end' | 'icalSequence'>;
  eventType: Pick<EventTypeRow, 'name' | 'location' | 'locationDetail'>;
  host: Pick<UserProfileRow, 'name' | 'email'>;
  attendee: Pick<UserProfileRow, 'name' | 'email'>;
  eventURL: string;
  isCanceled: boolean;
}) {
  const locationDescription = eventType.location === 'jitsi' ? ` on Jitsi Meet` : '';
  const ics = toIcs({
    host,
    altrep: eventType.location === 'jitsi' ? eventURL : '',
    location: eventType.location === 'jitsi' ? eventURL : eventType.locationDetail?.external || '',
    description: [
      `Meeting with ${host.name} and ${attendee.name}${locationDescription}.`,
      '',
      'Meeting URL:',
      '',
      eventURL,
    ].join('\n'),
    attendee,
    sequence: event.icalSequence,
    status: isCanceled ? 'CANCELLED' : 'CONFIRMED',
    summary: eventType.name,
    uid: event.id,
    dtstart: event.start,
    dtend: event.end,
    prodid: 'calendar.ruzuku.com',
  });

  return {
    ics,
    data: new Blob([ics], { type: 'text/calendar;charset=utf-8' }),
    filename: 'meeting.ics',
  };
}
