import { ComponentChildren } from 'preact';
import { RouteProps, router, useRouteParams } from '@components/router';
import { AppRoute } from 'client/lib/app-route/types';
import { GuideCoursePage } from '@components/guide-course-page';
import { PageNavBar, PageNavBody, PageNavLink } from '@components/page-nav-bar';
import { IcoBan, IcoBell, IcoEye, IcoRefresh, IcoUsers } from '@components/icons';
import { URLS } from 'shared/urls';
import { AccessFormatForm } from '@components/access-format-radios';
import { FullCourse } from 'server/types';
import { BtnPrimary } from '@components/buttons';
import { NotificationSettings } from './notification-settings';
import { FacilitatorsSettings } from './faciliator-settings';
import { LimitSettings } from './limit-settings';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { rpx } from 'client/lib/rpx-client';
import { HeadingSecondary } from '@components/headings';
import { CoursePrivacySettingsForm } from '@components/course-privacy-settings';
import { useState } from 'preact/hooks';
import { partialStateUpdater } from 'client/lib/hooks';
import { TranscriptSettings } from './transcript-settings';
import { IcoClosedCaptions } from '@components/icons/video-player';

type SettingTypes =
  | 'privacy'
  | 'limits'
  | 'notifications'
  | 'courseType'
  | 'facilitators'
  | 'captions';

type InitialData = Awaited<ReturnType<typeof load>>;

function NavbarItem({
  title,
  description,
  type,
  icon,
}: {
  type: SettingTypes;
  title: string;
  description: string;
  icon: ComponentChildren;
}) {
  const { courseId, settingsType: currentType = 'courseType' } = useRouteParams();

  return (
    <PageNavLink
      isSelected={type === currentType}
      href={URLS.guide.coursePage({
        courseId,
        page: `settings/${type}`,
      })}
    >
      <span class="flex py-2 gap-3">
        <span class="pt-1">{icon}</span>
        <span class="flex flex-col">
          <strong class="capitalize">{title}</strong>
          <span class="opacity-75 font-normal text-xs">{description}</span>
        </span>
      </span>
    </PageNavLink>
  );
}

function CourseTypeSettings({
  course,
  onUpdate,
}: {
  course: FullCourse;
  onUpdate: (accessFormat: FullCourse['accessFormat']) => void;
}) {
  const { terminology } = useCurrentTenant();
  return (
    <div class="flex flex-col gap-6">
      <HeadingSecondary>Change {terminology.course} type</HeadingSecondary>
      <AccessFormatForm
        courseId={course.id}
        accessFormat={course.accessFormat}
        isAbsoluteSchedule={!!course.isAbsoluteSchedule}
        onSuccess={onUpdate}
      >
        <footer>
          <BtnPrimary class="rounded-full px-12">Save</BtnPrimary>
        </footer>
      </AccessFormatForm>
    </div>
  );
}

function Page({ data, route }: RouteProps<InitialData>) {
  const tenant = useCurrentTenant();
  const user = useCurrentUser();
  const [course, setCourse] = useState(data.course);
  const { terminology } = tenant;
  const currentType = (route.params.settingsType || 'courseType') as SettingTypes;
  const captionsAvailable = !tenant.isCore || user?.tier === 'pro';

  const updateCourse = partialStateUpdater(setCourse);

  return (
    <GuideCoursePage course={course} type="settings">
      <PageNavBar title="Settings">
        <PageNavBody class="gap-1">
          <NavbarItem
            type="courseType"
            title={`${terminology.course} Type`}
            description={`Change your ${terminology.course} type`}
            icon={<IcoRefresh />}
          />
          <NavbarItem
            type="limits"
            title="Limits"
            description={`Modify limits for your ${terminology.course}`}
            icon={<IcoBan />}
          />
          <NavbarItem
            type="privacy"
            title="Privacy & Community"
            description={`Manage privacy & community preferences of ${terminology.course}`}
            icon={<IcoEye />}
          />
          <NavbarItem
            type="notifications"
            title="Notifications"
            description={`Manage which notifications you receive for this ${terminology.course}`}
            icon={<IcoBell />}
          />
          {!tenant.isCore && course.level === 'guide' && (
            <NavbarItem
              type="facilitators"
              title={terminology.Facilitators}
              description={`Configure who can help you manage this ${terminology.course}`}
              icon={<IcoUsers />}
            />
          )}
          {captionsAvailable && (
            <NavbarItem
              type="captions"
              title="Captions & Transcripts"
              description={`Manage captions & transcripts preferences for this ${terminology.course}`}
              icon={<IcoClosedCaptions class="w-4 h-4" />}
            />
          )}
        </PageNavBody>
      </PageNavBar>
      <div class="grow p-8 sm:px-10  max-w-4xl">
        {currentType === 'courseType' && (
          <CourseTypeSettings
            course={course}
            onUpdate={(accessFormat) =>
              updateCourse({
                accessFormat,
              })
            }
          />
        )}
        {currentType === 'limits' && <LimitSettings course={course} onUpdate={updateCourse} />}
        {currentType === 'notifications' && <NotificationSettings course={course} />}
        {currentType === 'privacy' && (
          <CoursePrivacySettingsForm course={course} onUpdate={updateCourse} />
        )}
        {currentType === 'facilitators' && course.level === 'guide' && (
          <FacilitatorsSettings course={course} />
        )}
        {currentType === 'captions' && captionsAvailable && <TranscriptSettings course={course} />}
      </div>
    </GuideCoursePage>
  );
}

async function load(route: AppRoute) {
  const courseId = route.params.courseId;
  const course = await rpx.courses.getGuideCourse({ id: courseId });

  return {
    course,
  };
}

router.add({
  url: 'manage/courses/:courseId/settings/:settingsType',
  load,
  render: Page,
  authLevel: 'guide',
});

router.add({
  url: 'manage/courses/:courseId/settings',
  load,
  render: Page,
  authLevel: 'guide',
});
