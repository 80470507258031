import { showError } from '@components/app-error';
import { UserProfileIcon } from '@components/avatars';
import { Dropdown, MenuItem } from '@components/dropdown';
import { useConfiguration, useCurrentUser } from '@components/router/session-context';
import { hasLevel } from 'shared/auth';
import { rpx } from 'client/lib/rpx-client';
import { Button } from '@components/buttons';
import { useIntl } from 'shared/intl/use-intl';

interface Props {
  class?: string;
}

export function UserMenu({ class: className = '' }: Props) {
  const intl = useIntl();
  const user = useCurrentUser();
  const configuration = useConfiguration();
  const isMimicking = !!configuration?.coreUser;

  async function stopMimickingUser() {
    try {
      await rpx.admin.unmimicUser();
      // Opens the homepage and hard reloads the app
      window.location.href = `/admin/people/${user?.id}`;
    } catch (err) {
      showError(err);
    }
  }

  if (!user) {
    return null;
  }

  return (
    <div class={`flex items-center font-normal ${className}`}>
      <Dropdown
        hideDownIcon
        triggerClass="hover:bg-white/20 hover:shadow-md rounded-full"
        position={isMimicking ? 'top-4 right-0' : undefined}
        renderMenu={() => (
          <>
            {configuration?.coreUser && (
              <Button
                class="w-full flex items-center p-4 text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 border-b dark:border-gray-700"
                role="menuitem"
                onClick={stopMimickingUser}
              >
                <UserProfileIcon user={configuration.coreUser} size="w-6 h-6 mr-3" />
                <div class="flex flex-col">
                  <span>Switch back to</span>
                  <span class="font-bold">{configuration.coreUser.name}</span>
                </div>
              </Button>
            )}
            {!configuration?.coreUser && user && (
              <div class="flex items-center p-4 mb-4 text-left border-b dark:border-gray-700 text-gray-700 dark:text-gray-100">
                <UserProfileIcon user={user} size="w-6 h-6 mr-3" />
                <div class="flex flex-col">
                  <span class="font-bold">{user.name}</span>
                </div>
              </div>
            )}
            <div class="flex flex-col px-2">
              <MenuItem href="/account">{intl('Account')}</MenuItem>
              {hasLevel(user, 'admin') && (
                <MenuItem href="/admin/people">{intl('Manage Site')}</MenuItem>
              )}
              <MenuItem href="/courses">{intl('My Courses')}</MenuItem>
              <MenuItem
                href={
                  hasLevel(user, 'guide')
                    ? 'https://support.ruzuku.com/collection/741-guide-help'
                    : configuration.tenant.supportUrl ||
                      'https://support.ruzuku.com/collection/746-student-help'
                }
              >
                {intl('Support')}
              </MenuItem>
              <MenuItem href="/logout">{intl('Logout')}</MenuItem>
            </div>
          </>
        )}
      >
        <span
          class={`${
            isMimicking
              ? 'bg-green-600 text-white p-1 sm:pl-4 ring-2 ring-green-200 rounded-full'
              : ''
          } inline-flex items-center gap-4`}
        >
          {isMimicking && (
            <span class="hidden sm:block font-semibold whitespace-nowrap">
              Mimicking {user.name}
            </span>
          )}
          <UserProfileIcon
            user={user}
            size="w-8 h-8 sm:w-8 sm:h-8 text-xs ring-2 ring-black/50 hover:ring-black"
          />
        </span>
      </Dropdown>
    </div>
  );
}
