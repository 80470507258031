import { FormGroup } from '@components/async-form';
import { AutosizeText } from '@components/autosize-text';
import { Button } from '@components/buttons';
import { useState } from 'preact/hooks';
import { BULK_INSERT_LIMIT } from 'shared/consts';
import { pluralize } from 'shared/formatting';
import { extractEmails } from 'shared/parsing';
import { URLS } from 'shared/urls';
import { BaseInvitation, Invitation, normalizeInvitation } from './data';
import { InvitationsGraphic } from './empty-screen';
import { showProcessingModal } from '@components/processing-modal';
import quikpik from 'quikpik';
import { AccessLimitsFormFields } from '../pmts/components/access-limits-tab';
import { ModalForm } from '@components/modal-form';

interface Props {
  courseId: UUID;
  send: (data: { emails: string[]; accessDuration?: number }) => Promise<BaseInvitation[]>;
  hideModifyText?: boolean;
  onCancel(): void;
  onSent(result: { invitations: Invitation[]; alreadyEnrolledEmails: string[] }): void;
}

function csvPicker(onPick: (content: string) => void) {
  quikpik({
    sources: ['filepicker'],
    accept: 'text/csv',
    customProgress: true,
    upload({ files }) {
      const file = files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => onPick(reader.result as string), false);
      reader.readAsText(file);
    },
  });
}

export function InvitationModal({ courseId, send, hideModifyText, onSent, onCancel }: Props) {
  const [text, setText] = useState('');
  const numEmails = text.split('@').length - 1;

  return (
    <ModalForm
      autoFocus={false}
      title="Invite new students"
      subtitle={
        <>
          Invite up to {BULK_INSERT_LIMIT} students one per line. You can also{' '}
          <Button class="text-indigo-600" onClick={() => csvPicker(setText)}>
            load a CSV file
          </Button>
          .
        </>
      }
      onClose={onCancel}
      onSubmit={async (formData: { accessDuration?: string }) => {
        const emails = extractEmails(text);
        const result = await showProcessingModal({
          promise: send({
            emails,
            accessDuration: formData.accessDuration
              ? parseInt(formData.accessDuration, 10)
              : undefined,
          }),
          title: 'Sending invitations...',
          children: (
            <div class="scale-50">
              <InvitationsGraphic />
            </div>
          ),
        });
        if (result) {
          const sentEmails = new Set(result.map((inv) => inv.email));
          const alreadyEnrolledEmails = emails.filter((email) => !sentEmails.has(email));
          onSent({
            invitations: result.map(normalizeInvitation),
            alreadyEnrolledEmails,
          });
        }
      }}
      confirmButtonText={
        <>
          Send {numEmails || ''} {pluralize('invitation', numEmails)}
        </>
      }
    >
      <section class="flex flex-col gap-4">
        <FormGroup prop="emails" class="w-full">
          <AutosizeText
            focusSelf
            name="emails"
            class="ruz-input p-2 min-h-32"
            value={text}
            onInput={(e: any) => setText(e.target.value)}
            placeholder="Emails, separated by space, comma, or newlines"
          />
        </FormGroup>
        {!hideModifyText && (
          <p>
            You may modify the invitation email text on the{' '}
            <a
              href={URLS.guide.coursePage({
                courseId,
                page: 'messages/invitation',
              })}
            >
              messages page
            </a>
            .
          </p>
        )}
        <AccessLimitsFormFields accessDuration={-1} />
      </section>
    </ModalForm>
  );
}
