/**
 * The auth form handles existing user login and new user registration
 * for checkout screens. New user registration can be disabled, if
 * desired.
 */

import type { ComponentChildren, h } from 'preact';
import { CurrentUser, useAuth, useCurrentTenant } from 'client/lib/auth';
import { useState } from 'preact/hooks';
import { useIntl } from 'shared/intl/use-intl';
import { CheckoutForm } from './checkout-form';
import { rpx } from 'client/lib/rpx-client';
import { FieldError, FormGroup, InputLabel } from '../async-form';
import { Button } from '../buttons';
import { IcoCheckCircle, IcoLock, IcoMail, IcoUser, IcoX } from '../icons';
import { Password } from '../password';
import { detectTimezone } from 'shared/dateutil';
import { autoFocusSelf } from 'client/utils/autofocus';
import { showError } from '@components/app-error';
import { CheckoutHeader } from './checkout-page';
import { showDialog } from '@components/dialog';

interface Props {
  /**
   * If true, the form only allows logins, not new user registration.
   */
  loginOnly?: boolean;
  title?: string;
  subtitle?: string;
  giftingToEmail?: string;
  finalActionText?: string;
  // If set, the local user will be initiated with this level.
  loginLevel?: 'guide' | 'student';
  onSubmit(user: CurrentUser): Promise<unknown>;
}

interface AuthProps {
  email: string;
  actionText?: string;
  children: ComponentChildren;
  onSubmit: Props['onSubmit'];
}

export function StrictPasswordRequirements() {
  const intl = useIntl();
  return (
    <>
      <ul class="list-disc ml-4">
        <li>{intl('At least 12 characters long')}</li>
        <li>{intl('At least 1 lower-cased letter')}</li>
        <li>{intl('At least 1 upper-cased letter')}</li>
        <li>{intl('At least 1 numeric character')}</li>
        <li>{intl('At least 1 special character (e.g. @#$!, etc)')}</li>
      </ul>
    </>
  );
}

function IconInputWrapper({
  icon,
  children,
}: {
  icon: ComponentChildren;
  children: ComponentChildren;
}) {
  return (
    <label class="relative flex">
      <span class="absolute flex items-center justify-center left-px top-px bottom-px w-10 bg-gray-50 rounded-l border-r">
        {icon}
      </span>
      {children}
    </label>
  );
}

function ForgotPassword({ email }: { email: string }) {
  const [mode, setMode] = useState<'init' | 'sending' | 'sent'>('init');
  const intl = useIntl();

  return (
    <footer class="pt-4 mt-4 border-t flex flex-col gap-4">
      {mode === 'sent' && (
        <div class="bg-green-50 p-4 text-green-800 flex items-center gap-4 rounded">
          <span class="flex-shrink-0">
            <IcoCheckCircle />
          </span>
          <p>{intl('We emailed you a link that will let you continue checkout.')}</p>
          <Button onClick={() => setMode('init')} class="ml-auto">
            <IcoX />
          </Button>
        </div>
      )}
      {mode !== 'sent' && (
        <Button
          class="text-indigo-600"
          isLoading={mode === 'sending'}
          onClick={async () => {
            try {
              setMode('sending');
              await rpx.auth.forgotPassword({
                email,
                returnURL: location.pathname + location.search,
              });
              setMode('sent');
            } catch (err) {
              showError(err);
              setMode('init');
            }
          }}
        >
          {intl('Forgot your password?')}
        </Button>
      )}
    </footer>
  );
}

function LoginForm({ onSubmit, email, children, actionText }: AuthProps) {
  const intl = useIntl();

  return (
    <CheckoutForm
      actionText={actionText || intl('Continue ⤑')}
      footer={<ForgotPassword email={email} />}
      onSubmit={async (data) => {
        const user = await rpx.auth.login({ email, password: data.password });
        await onSubmit(user);
      }}
    >
      {children}
      <FormGroup prop="password">
        <InputLabel>{intl('Password')}</InputLabel>
        <PasswordField />
      </FormGroup>
    </CheckoutForm>
  );
}

function NewUserForm({ onSubmit, email, children, actionText }: AuthProps) {
  const intl = useIntl();
  const tenant = useCurrentTenant();

  return (
    <CheckoutForm
      actionText={actionText || intl('Continue ⤑')}
      onSubmit={async (data) => {
        const user = await rpx.auth.registerNewUser({
          ...data,
          email,
          timezone: detectTimezone(),
        });
        await onSubmit(user);
      }}
    >
      {children}
      <FormGroup class="animate-slide-bottom" prop="password">
        <InputLabel>{intl('Password')}</InputLabel>
        <PasswordField />
        {tenant.strictPasswords && (
          <section class="text-gray-600 px-4 mt-6 flex flex-col gap-2">
            <p>{intl('Note: Passwords should meet the following requirements:')}</p>
            <StrictPasswordRequirements />
          </section>
        )}
      </FormGroup>
      <FormGroup prop="name" class="mb-2 animate-slide-bottom">
        <InputLabel>{intl(`Name`)}</InputLabel>
        <IconInputWrapper icon={<IcoUser />}>
          <input type="text" name="name" class="ruz-input pl-14" placeholder="Your name" required />
        </IconInputWrapper>
      </FormGroup>
    </CheckoutForm>
  );
}

function EmailField({
  footer,
  ...props
}: h.JSX.InputHTMLAttributes<HTMLInputElement> & { footer?: ComponentChildren }) {
  const intl = useIntl();
  return (
    <FormGroup prop="email" class="w-full">
      <InputLabel>{intl('Email')}</InputLabel>
      <IconInputWrapper icon={<IcoMail />}>
        <input
          type="email"
          ref={props.autofocus ? autoFocusSelf : undefined}
          required
          placeholder={intl('Your email address')}
          name="email"
          class="ruz-input block w-full sm:text-sm sm:leading-5 bg-transparent pl-14"
          data-private
          {...props}
        />
      </IconInputWrapper>
      {footer}
    </FormGroup>
  );
}

function PasswordField() {
  return (
    <IconInputWrapper icon={<IcoLock />}>
      <Password
        name="password"
        placeholder="Password"
        autocomplete="new-password"
        autoFocus
        wrapperClass="w-full"
        class="bg-transparent pl-14"
      />
    </IconInputWrapper>
  );
}

function EmailForm({
  onSubmit,
  loginOnly,
  giftingToEmail,
  email,
  setEmail,
}: {
  onSubmit(user?: { id: string }): void;
  loginOnly?: boolean;
  giftingToEmail?: string;
  email: string;
  setEmail(email: string): void;
}) {
  const intl = useIntl();
  const [error, setError] = useState('');

  return (
    <CheckoutForm
      actionText={intl('Continue ⤑')}
      onSubmit={async () => {
        if (email === giftingToEmail) {
          const isConfirmed = await showDialog({
            mode: 'warn',
            title: intl('You cannot create a gift for yourself.'),
            children: intl(
              'You entered the same email address for the gift recipient as your own. You will cancel the gift and make the purchase for yourself if you continue.',
            ),
            confirmButtonText: intl('Continue'),
          });
          if (!isConfirmed) {
            return;
          }
        }

        const existingUser = await rpx.courses.getIsMember({ email });
        if (loginOnly && !existingUser) {
          setError(intl(`No account found with email "{email}"`, { email }));
          return;
        }
        onSubmit(existingUser);
      }}
    >
      <EmailField
        autofocus
        // @ts-ignore
        defaultValue={email}
        value={email}
        onInput={(e: any) => {
          setEmail(e.target.value);
          setError('');
        }}
        footer={error && <FieldError>{error}</FieldError>}
        data-private
      />
    </CheckoutForm>
  );
}

export function AuthForm({
  subtitle,
  title,
  giftingToEmail,
  loginLevel,
  onSubmit,
  loginOnly,
  finalActionText,
}: Props) {
  const auth = useAuth();
  const intl = useIntl();
  const [email, setEmail] = useState('');
  const [mode, setMode] = useState<'init' | 'login' | 'newuser'>('init');

  const backButton = (
    <div>
      <InputLabel>{intl('Email')}</InputLabel>
      <Button
        class="flex gap-2 text-left w-full p-0 ruz-input bg-gray-50 rounded border items-center relative pl-12"
        onClick={() => setMode('init')}
      >
        <span class="flex absolute inset-y-0 left-0 items-center justify-center w-10 border-r">
          <IcoMail />
        </span>
        <span class="flex-grow flex items-center p-2 py-1.5">{email}</span>
        <span class="px-2.5 flex items-center justify-center">
          <IcoX class="size-4 opacity-75" />
        </span>
      </Button>
    </div>
  );

  const onLogin = async (user: CurrentUser) => {
    if (!user) {
      return;
    }
    return onSubmit(
      await auth.login(
        Promise.resolve({
          ...user,
          level: loginLevel || 'student',
        }),
      ),
    );
  };

  if (mode === 'init') {
    return (
      <>
        <CheckoutHeader title={title || intl('Sign Up')} subtitle={subtitle} />
        <EmailForm
          email={email}
          setEmail={setEmail}
          giftingToEmail={giftingToEmail}
          loginOnly={loginOnly}
          onSubmit={(existingUser) => setMode(existingUser ? 'login' : 'newuser')}
        />
      </>
    );
  }

  if (mode === 'newuser') {
    return (
      <>
        <CheckoutHeader title={intl('Create Your Account')} subtitle={subtitle} />
        <NewUserForm onSubmit={onLogin} email={email} actionText={finalActionText}>
          {backButton}
        </NewUserForm>
      </>
    );
  }

  return (
    <>
      <CheckoutHeader title={intl('Welcome Back!')} subtitle={subtitle} />
      <LoginForm onSubmit={onLogin} email={email} actionText={finalActionText}>
        {backButton}
      </LoginForm>
    </>
  );
}
