import { useMemo, useState } from 'preact/hooks';
import { rpx } from 'client/lib/rpx-client';
import dayjs from 'dayjs';
import { useTryAsyncData } from 'client/lib/hooks';
import { datesEq, mkdate } from '@components/date-picker';
import { DateRange, getAvailability } from 'shared/scheduling';
import { Calendar } from './calendar';
import { time12Formatter, time24Formatter, weekdayFormatter } from './dateutil';
import { AvailabilityRow, EventTypeRow } from 'server/types/cal-schema';
import { ComponentChildren } from 'preact';
import { Button } from '@components/buttons';

function dateClass(opts: { date: Date; today: Date; isAvailable: boolean; selectedDate?: Date }) {
  const isToday = datesEq(opts.date, opts.today);
  const isActive = datesEq(opts.selectedDate, opts.date);
  const today = isToday && opts.isAvailable ? 'font-bold' : '';
  const active = isActive ? 'bg-indigo-600 text-white' : '';
  const available = opts.isAvailable
    ? 'bg-gray-100 text-black hover:bg-indigo-500 hover:text-white cursor-pointer'
    : 'text-gray-500';
  return `aspect-square flex items-center justify-center transition-all rounded-full ${today} ${
    active || available
  }`;
}

function BtnHour(props: { onClick(): void; isSelected: boolean; children: ComponentChildren }) {
  return (
    <button
      type="button"
      class={`${
        props.isSelected ? 'bg-gray-200' : ''
      } px-2 rounded hover:bg-gray-100 transition-all`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

function TimeSlots({
  hour12,
  availability,
  selected,
  makeHref,
  onClick,
}: {
  hour12: boolean;
  selected?: DateRange;
  availability: DateRange[];
  makeHref?(opts: { schedule: DateRange }): string;
  onClick?(opts: { schedule: DateRange }): void;
}) {
  const fmt = hour12 ? time12Formatter : time24Formatter;
  return (
    <section class="flex flex-col gap-2">
      {!availability.length && <p>No times available.</p>}
      {availability.map((x) => {
        return (
          <Button
            key={x.start}
            href={makeHref && makeHref({ schedule: x })}
            onClick={onClick && (() => onClick({ schedule: x }))}
            class={`text-center p-2 rounded hover:bg-indigo-600 hover:text-white border border-gray-200 transition-all ${
              x.start.getTime() === selected?.start.getTime()
                ? 'bg-indigo-600 border-indigo-600 text-white'
                : 'text-inherit'
            }`}
          >
            {fmt.format(x.start)}
          </Button>
        );
      })}
    </section>
  );
}

export function ScheduleBooking({
  eventType,
  availability,
  attendeeTimeZone,
  schedule,
  hour12,
  onScheduleChange,
  onHour12Change,
  makeHref,
  onClick,
  compact,
}: {
  compact?: boolean;
  eventType: EventTypeRow;
  attendeeTimeZone: string;
  availability: Pick<AvailabilityRow, 'timeslots' | 'timezone'>;
  schedule?: DateRange;
  hour12?: boolean;
  onScheduleChange(schedule: DateRange): void;
  onHour12Change(value: boolean): void;
  makeHref?(opts: { schedule: DateRange }): string;
  onClick?(opts: { schedule: DateRange }): void;
}) {
  const [focusedDate, setFocusedDate] = useState(() =>
    mkdate((dt) => dt.setHours(0, 0, 0, 0), schedule?.start),
  );
  const focusedMonth = dayjs(focusedDate).format('YYYY-MM-01');
  const selectedDate = schedule?.start;
  const bookings = useTryAsyncData(
    () =>
      rpx.ruzcal.getMonthlyBookings({
        month: focusedMonth,
        hostId: eventType.hostId,
      }),
    [focusedMonth],
  );
  const generateAvailability = (date: Date) => {
    return getAvailability({
      availability: availability,
      duration: eventType.duration,
      maxPerDay: eventType.maxPerDay || 0,
      minNotice: eventType.minNotice || 0,
      prefixBufferTime: eventType.bufferMinsBefore || 0,
      suffixBufferTime: eventType.bufferMinsAfter || 0,
      attendeeTimeZone: attendeeTimeZone,
      date,
      unavailable: bookings.data || [],
    });
  };

  const selectedAvailability = useMemo(() => {
    const unavailable = bookings.data;
    if (!unavailable || !selectedDate) {
      return [];
    }
    return Array.from(generateAvailability(selectedDate));
  }, [bookings.data, selectedDate]);

  return (
    <>
      <section
        class={`lg:col-span-2 sm:border-t-0 py-10 sm:pt-0 lg:p-0 lg:border-0 lg:px-4 ${
          compact ? 'lg:pl-0' : ''
        }`}
      >
        <Calendar
          onSelect={(dt) => {
            onScheduleChange({ start: dt, end: dt });
            document.querySelector('.js-time-header')?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }}
          focusedDate={focusedDate}
          setFocusedDate={setFocusedDate}
          isAvailable={(opts) =>
            !bookings.isLoading && !!generateAvailability(opts.date).next().value
          }
          renderDate={(opts) => {
            const className = dateClass({ ...opts, selectedDate });
            return <span class={className}>{opts.date.getDate()}</span>;
          }}
        />
      </section>
      <section
        key={selectedDate?.toDateString()}
        class={`flex flex-col gap-2 an-fade-in-left sm:col-end-3 lg:col-end-auto`}
      >
        <header class="js-time-header flex items-center justify-between gap-4">
          <span class="font-semibold">{weekdayFormatter.format(selectedDate)}</span>
          <span class="flex border gap-1 rounded-md p-1">
            <BtnHour isSelected={!!hour12} onClick={() => onHour12Change(true)}>
              12h
            </BtnHour>
            <BtnHour isSelected={!hour12} onClick={() => onHour12Change(false)}>
              24h
            </BtnHour>
          </span>
        </header>
        <div class="flex flex-col grow overflow-auto relative ">
          <section class="lg:absolute inset-x-0 top-0">
            {!bookings.isLoading && (
              <TimeSlots
                hour12={!!hour12}
                availability={selectedAvailability}
                selected={schedule}
                makeHref={makeHref}
                onClick={onClick}
              />
            )}
          </section>
        </div>
      </section>
    </>
  );
}
