/**
 * The pricing section for the sales page builder.
 */
import { ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import {
  EditorProps,
  SalesBlockInitialState,
  ViewerProps,
  SubsectionDragProvider,
  SubsectionEditor,
  SubsectionWrapper,
  useIdGen,
  getProp,
  MultilineProp,
  RichProp,
  setProp,
  BtnAdd,
} from '@components/page-builder';
import * as fmt from 'shared/payments/fmt';
import { showPriceModal } from './price-modal';
import { useRouteData } from '@components/router';
import { PriceRow } from 'server/types';
import { coursePrices } from 'shared/urls';
import { IcoCheck } from '@components/icons';
import { intl } from 'shared/intl/use-intl';
import { CtaButton, CTAState } from '@components/page-builder/cta-button';
import { Color } from 'shared/colors';
import { showDialog } from '@components/dialog';
import { Button } from '@components/buttons';
import { LoadedProps } from 'client/lib/loaders';
import { load } from './guide-course-salespage';

interface Section {
  id: number;
  title: string;
  currency: string;
  price: string;
  cents: string;
  priceSubtext: string;
  bullets: string;
  isDefault?: boolean;
  cta: CTAState;
}

export interface State {
  title: string;
  sections: Section[];
}

function priceToSection({
  courseId,
  isProduct,
  price: p,
  id,
}: {
  courseId: string;
  isProduct?: boolean;
  price: PriceRow;
  id: number;
}): Section {
  const [price, cents] = fmt.price({ ...p, omitCurrency: true }).split('.');
  return {
    id,
    title: p.name,
    currency: fmt.currencySymbol(p.currency),
    price,
    cents: cents || '',
    priceSubtext: fmt.priceSuffix({
      item: p,
      intl: intl,
    }),
    bullets: `Live meetings\nAccess to all content\nDiscussions\n1:1 help getting started`,
    cta: {
      text: 'Enroll Now ⤑',
      url: coursePrices.checkoutUrl({
        isProduct,
        courseId,
        priceId: p.id,
      }),
      bgcolor: Color.gray50,
    },
  };
}

export const type = 'pricing';

export const name = 'Pricing Table';

export const initialBlockState: SalesBlockInitialState = {
  type,
  bgcolor: 'rgb(30, 41, 59)',
  paddingt: 'md',
  paddingb: 'md',
};

export const initialState: State = {
  title: `<h2>Simple pricing</h2><p>Easily pay with your preferred method, and get access today!</p><p><br></p><p>Unbeatable value, available for a limited time only.</p>`,
  sections: [],
};

function MiniPrice({ price, numBullets = 2 }: { price: string; numBullets?: number }) {
  const bullets = new Array<string>(numBullets).fill('');
  return (
    <span class="flex flex-col items-center justify-center border rounded p-2">
      <span class="inline-flex font-bold justify-center items-center mb-2 w-full">{price}</span>
      {bullets.map((_, i) => (
        <span key={i} class="inline-block bg-gray-400 rounded w-1/3 h-1 mb-1"></span>
      ))}
      <span class="inline-block bg-gray-500 rounded w-1/4 h-1 mb-1"></span>
    </span>
  );
}

export function MiniView() {
  return (
    <span class="grid grid-cols-3">
      <MiniPrice price="$99" />
      <MiniPrice price="€88" />
      <MiniPrice price="£77" />
    </span>
  );
}

export async function onDelete() {
  return showDialog({
    children: `Without a pricing table, students will be unable to purchase your course.`,
    title: `Delete pricing table?`,
    mode: 'warn',
    confirmButtonText: 'Delete pricing table',
  });
}

function CheckItem({ children }: { children: ComponentChildren }) {
  return (
    <li class="flex">
      <span class="mt-1 mr-4">
        <IcoCheck class="w-5 h-5" />
      </span>
      <span>{children}</span>
    </li>
  );
}

function PriceEditor(
  props: (EditorProps<State> | ViewerProps<State>) & {
    path: Array<string | number>;
    isPrimary: boolean;
  },
) {
  const pageData = useRouteData<LoadedProps<typeof load>['data']>();
  const [isEditingBullets, setIsEditingBullets] = useState(false);
  const { path, isBgLight } = props;
  const readonly = !props.setState;
  const state: Section = getProp(props);
  const primaryStyle = isBgLight ? 'bg-gray-100' : 'bg-indigo-200 bg-opacity-30';
  const baseStyle = isBgLight ? 'bg-gray-50' : 'bg-indigo-200 bg-opacity-20';
  const isDefault = state.isDefault === undefined ? props.isPrimary : state.isDefault;

  return (
    <div
      class={`relative p-12 rounded-3xl ${isDefault ? primaryStyle : baseStyle} ${
        isDefault ? 'shadow-3xl' : 'scale-90 shadow-2xl'
      } lg:w-96 max-w-full`}
    >
      <header class="mb-8 flex text-6xl font-medium relative group/item">
        <sup class="opacity-75 text-4xl mr-1 mt-4">{state.currency}</sup>
        <div class="flex flex-col flex-grow">
          <div class="flex">
            {state.price}
            {state.cents && <sub class="opacity-75 text-4xl mt-4">.{state.cents}</sub>}
          </div>
          <div class="text-base font-normal">
            <MultilineProp {...props} path={[...path, 'priceSubtext']} />
          </div>
        </div>
        {!readonly && (
          <Button
            class="group-hover/item:opacity-100 opacity-0 flex text-sm font-medium absolute -left-2 -top-10 p-2 hover:bg-gray-900 hover:text-white rounded-full transition-all"
            onClick={() =>
              showPriceModal().then((p) => {
                if (!p) {
                  return;
                }
                const section = priceToSection({
                  courseId: pageData.course.id,
                  isProduct: pageData.course.isProduct,
                  price: p,
                  id: state.id,
                });
                props.setState((s) => ({
                  ...s,
                  sections: s.sections.map((x) =>
                    x.id === section.id
                      ? {
                          ...x,
                          price: section.price,
                          cents: section.cents,
                          currency: section.currency,
                          priceSubtext: section.priceSubtext,
                          cta: {
                            ...x.cta,
                            url: section.cta.url,
                          },
                        }
                      : x,
                  ),
                }));
              })
            }
          >
            <span>Change price point</span>
          </Button>
        )}
      </header>

      {isEditingBullets && !readonly && (
        <div class="min-h-40 flex rounded-2xl bg-transparent p-2">
          <MultilineProp
            {...props}
            placeholder="Add bullet points, one per line"
            path={[...path, 'bullets']}
            focusSelf
            onBlur={() => setIsEditingBullets(false)}
          />
        </div>
      )}
      {!isEditingBullets && (
        <ol
          class={`text-base space-y-4 mb-10 ${
            readonly ? '' : 'cursor-text hover:bg-black hover:bg-opacity-5'
          } rounded-2xl`}
          tabIndex={readonly ? -1 : 1}
          onFocus={() => !readonly && setIsEditingBullets(true)}
        >
          {!readonly && !state.bullets.length && <li class="px-6">Add bullet points</li>}
          {state.bullets.split('\n').map((b) => b && <CheckItem key={b}>{b}</CheckItem>)}
        </ol>
      )}
      <footer>
        <CtaButton {...props} path={[...path, 'cta']} fullWidth />
      </footer>

      {!readonly && (
        <aside class="mt-6 flex justify-center">
          <label class="inline-flex items-center gap-3">
            <input
              type="checkbox"
              checked={isDefault}
              class="ring-2 focus:ring-indigo-500 h-4 w-4 bg-gray-800 text-gray-800 border-gray-800 ring-gray-800 rounded"
              onClick={(e: any) => {
                setProp(
                  {
                    ...props,
                    path: [...path, 'isDefault'],
                  },
                  e.target.checked,
                );
              }}
            />
            Recommended price
          </label>
        </aside>
      )}
    </div>
  );
}

export function Editor(props: EditorProps<State> | ViewerProps<State>) {
  const pageData = useRouteData<LoadedProps<typeof load>['data']>();
  const nextId = useIdGen(props.state.sections);
  const readonly = !props.setState;
  const { state } = props;
  const editProps = props as EditorProps<State>;

  const titleEditor = <RichProp {...props} path="title" />;
  const isSingle = state.sections.length <= 1;
  const showTitle = !readonly || (!!props.state.title && props.state.title !== '<p><br></p>');

  return (
    <SubsectionDragProvider
      table={type}
      onReorder={(reorder) => editProps.setState((s) => ({ ...s, sections: reorder(s.sections) }))}
    >
      <SubsectionWrapper>
        {!isSingle && <div class="mb-20 px-10">{titleEditor}</div>}

        <div
          id="pricing"
          class={`max-w-5xl w-full flex flex-col lg:flex-row ${
            isSingle
              ? 'justify-around'
              : 'space-y-16 lg:space-y-0 lg:space-x-20 items-center justify-center'
          } mx-auto`}
        >
          {isSingle && showTitle && (
            <div class="text-lg grow lg:max-w-96 mb-20 lg:mt-20">{titleEditor}</div>
          )}
          {state.sections.map((s, i) => (
            <SubsectionEditor
              key={s.id}
              id={s.id}
              class={i === 1 ? '' : 'scale-95'}
              disabled={readonly}
              table={type}
              onDelete={() =>
                editProps.setState((s) => ({
                  ...s,
                  sections: s.sections.filter((_, x) => x !== i),
                }))
              }
            >
              <PriceEditor
                {...props}
                path={['sections', i]}
                isBgLight={props.isBgLight}
                isPrimary={i === 1}
              />
            </SubsectionEditor>
          ))}
        </div>

        {editProps.isSelected && state.sections.length < 3 && (
          <footer class="flex justify-center mt-8">
            <BtnAdd
              isBgLight={props.isBgLight}
              onClick={() =>
                showPriceModal().then((price) => {
                  price &&
                    editProps.setState((s) => ({
                      ...s,
                      sections: [
                        ...s.sections,
                        priceToSection({
                          courseId: pageData.course.id,
                          isProduct: pageData.course.isProduct,
                          price,
                          id: nextId(),
                        }),
                      ],
                    }));
                })
              }
            >
              + Add Price
            </BtnAdd>
          </footer>
        )}
      </SubsectionWrapper>
    </SubsectionDragProvider>
  );
}

export const Viewer = Editor;

export function generateState({
  courseId,
  isProduct,
  prices,
}: {
  courseId: UUID;
  isProduct?: boolean;
  prices: PriceRow[];
}): State {
  return {
    title: `<h2>Simple pricing</h2><p>Easily pay with your preferred method, and get access today!</p><p><br></p><p>Unbeatable value, available for a limited time only.</p>`,
    sections: prices.map((p, i) => priceToSection({ courseId, isProduct, price: p, id: i })),
  };
}
