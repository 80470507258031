import { RouteProps, router } from '@components/router';
import { NavBar } from './nav-bar';
import { meetingRoute, meetingUrl } from './urls';
import { FullCourse, Meeting } from 'server/types';
import { useEffect } from 'preact/hooks';
import { MeetingDetail } from './meeting-detail';
import { NewMeetingForm } from './new-meeting-form';
import { Case } from '@components/conditional';
import { groupMeetingsByStatus } from 'shared/meeting-utils';
import { EmptyPage } from '@components/empty-page';
import { IcoPresentation } from '@components/icons';
import { GuideCoursePage } from '@components/guide-course-page';
import { URLS } from 'shared/urls';
import { completeCourseStep } from '@components/course-checklist';
import { useCurrentTenant } from '@components/router/session-context';
import { load, addMeeting, deleteMeeting, updateMeeting } from './state';

interface RouteData {
  course: FullCourse;
  meetings: Meeting[];
}

function Page(props: RouteProps<RouteData>) {
  const { state, setState } = props;
  const { course, meetings } = state;
  const { meetingId } = props.route.params;
  const { terminology } = useCurrentTenant();
  const isCreatePage = meetingId === 'new';
  const selectedMeeting = meetings.find((w) => w.id === meetingId);
  const { draft, past, upcoming } = groupMeetingsByStatus(meetings);

  // Go to the first available meeting, if none was requested.
  useEffect(() => {
    if (!isCreatePage && !selectedMeeting && meetings.length > 0) {
      const firstId = upcoming[0]?.id ?? past[0]?.id;
      router.rewrite(meetingUrl({ courseId: course.id, meetingId: firstId }));
    }
  }, [isCreatePage, selectedMeeting, course]);

  return (
    <GuideCoursePage
      course={course}
      isLoading={!course}
      viewLink={
        selectedMeeting &&
        URLS.student.meeting({
          course,
          meeting: selectedMeeting,
        })
      }
      documentTitle={selectedMeeting?.title}
      type="meetings"
    >
      <Case when={meetings.length > 0 && !isCreatePage}>
        <NavBar draft={draft} upcoming={upcoming} past={past} />
      </Case>
      <div class="flex flex-col grow p-4 md:p-8 max-w-5xl mx-auto">
        <Case
          when={!isCreatePage}
          fallback={
            <NewMeetingForm
              onCreate={(meeting) => {
                addMeeting(setState, meeting);
                router.goto(meetingUrl({ courseId: course.id, meetingId: meeting.id }));
                completeCourseStep('createMeeting');
              }}
            />
          }
        >
          <Case when={meetings.length === 0}>
            <EmptyPage
              Ico={IcoPresentation}
              actionText={`Create your first ${terminology.meeting}`}
              description={`You haven't created any ${terminology.meetings}.`}
              title={`Create a ${terminology.meeting}`}
              centeredDescription
              href={meetingUrl({ courseId: course.id, page: 'new' })}
            />
          </Case>
          {selectedMeeting && (
            <MeetingDetail
              course={course}
              key={selectedMeeting.id}
              meeting={selectedMeeting}
              hasEnrolledStudents={course.numStudents ? course.numStudents > 0 : false}
              onAdd={(meeting) => addMeeting(setState, meeting)}
              onDelete={() => deleteMeeting(setState, selectedMeeting.id)}
              onUpdate={(meeting) => updateMeeting(setState, selectedMeeting.id, meeting)}
            />
          )}
        </Case>
      </div>
    </GuideCoursePage>
  );
}

router.add({
  url: meetingRoute(''),
  render: Page,
  load,
  authLevel: 'guide',
});

router.add({
  url: meetingRoute(':meetingId'),
  load,
  render: Page,
  authLevel: 'guide',
});

router.add({
  url: meetingRoute(':meetingId/:tab'),
  load,
  render: Page,
  authLevel: 'guide',
});
