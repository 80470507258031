import { FullCourse } from 'server/types';
import { AsyncForm, FormGroup, FormSubSection, InputLabel, Subtext } from '@components/async-form';
import { rpx } from 'client/lib/rpx-client';
import { BtnPrimary } from '@components/buttons';
import { showToast } from '@components/toaster';
import { COURSE_STUDENT_LIMIT } from 'shared/consts';
import { useCurrentTenant } from '@components/router/session-context';
import { HeadingSecondary } from '@components/headings';

const store = rpx.courseSettings;

interface Props {
  course: FullCourse;
  onUpdate: (updates: Pick<FullCourse, 'maxMemberships'>) => void;
}

export function LimitSettings({ course, onUpdate }: Props) {
  const { terminology } = useCurrentTenant();

  async function submit(formData: { maxMemberships: number }) {
    const newLimit = formData.maxMemberships
      ? Number(formData.maxMemberships)
      : COURSE_STUDENT_LIMIT;

    await store.setMaxMemberships({
      courseId: course.id,
      maxMemberships: newLimit,
    });
    showToast({
      title: 'Settings changed',
      message: `${terminology.course} limit settings have been updated.`,
      type: 'ok',
    });
    onUpdate({
      maxMemberships: newLimit,
    });
  }

  return (
    <AsyncForm class="flex flex-col gap-6" onSubmit={submit}>
      <HeadingSecondary>{terminology.course} Limits</HeadingSecondary>
      <FormSubSection>
        <InputLabel>Seats available for this {terminology.course}</InputLabel>
        <Subtext>Invitations do not count towards this limit.</Subtext>
        <FormGroup prop="maxMemberships">
          <input
            class="inline-ruz-input w-full"
            type="text"
            name="maxMemberships"
            placeholder="Unlimited"
            defaultValue={`${course.maxMemberships || COURSE_STUDENT_LIMIT}`}
          />
        </FormGroup>
      </FormSubSection>
      <footer>
        <BtnPrimary class="rounded-full px-12">Save</BtnPrimary>
      </footer>
    </AsyncForm>
  );
}
