import { StudentPage } from '@components/student-page';
import { RouteProps, router } from '@components/router';
import { MemberProfile } from './member-profile';
import { MemberList } from './member-list';
import { useEffect } from 'preact/hooks';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useCurrentUser } from '@components/router/session-context';
import { URLS } from 'shared/urls';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { Spinner } from '@components/spinner';
import { showError } from '@components/app-error';
import { debounce } from 'client/utils/debounce';
import { useMemo } from 'react';
import { serialAsync } from 'client/utils/serial-async';
import { LoadingIndicator } from '@components/loading-indicator';
import { CourseWithGuide, Member } from 'server/types';
import { AppRoute } from 'client/lib/app-route/types';

type State = {
  /**
   * User list.
   */
  users: Member[];

  /**
   * The cursor in our paginated list of users.
   */
  cursor?: string;

  /*
   * The user list is loading.
   */
  loading: boolean;

  /*
   * It means the the feed has more items to display.
   */
  hasMore: boolean;

  selectedUser?: RpxResponse<typeof rpx.members.getCourseMember>;

  course: CourseWithGuide;
};

async function load({ params }: AppRoute): Promise<State> {
  const { courseId, userId } = params;

  const course = await rpx.courses.getStudentCourse({ id: courseId });
  const selectedUser =
    !course.hidePeople && userId
      ? await rpx.members.getCourseMember({
          courseId,
          userId,
        })
      : undefined;

  return {
    selectedUser,
    course,
    users: [],
    loading: false,
    hasMore: false,
  };
}
export type SelectedUser = NonNullable<RpxResponse<typeof rpx.members.getCourseMember>>;

type PageData = Awaited<ReturnType<typeof load>>;

function RedirectToCourse({ course }: { course: PageData['course'] }) {
  useEffect(() => {
    setTimeout(() => router.goto(URLS.student.course({ course })));
  }, []);

  return <Spinner />;
}

interface LoadNextPageOpts {
  courseId: UUID;
  searchTerm?: string;
  cursor?: string;
  setState: RouteProps<PageData>['setState'];
}

async function loadNextPage({ courseId, cursor, searchTerm, setState }: LoadNextPageOpts) {
  // This is the requested member count in each batch.
  const PAGE_COUNT = 15;

  setState((s) => ({ ...s, loading: true }));

  try {
    const result = await rpx.members.getCourseMembers({
      courseId,
      cursor,
      limit: PAGE_COUNT,
      search: searchTerm,
    });
    setState((s) => ({
      ...s,
      users: cursor ? [...s.users, ...result.users] : result.users,
      cursor: result.cursor,
      hasMore: result.hasMore,
      loading: false,
    }));
  } catch (err) {
    setState((s) => ({ ...s, loading: false }));
    showError(err);
  }
}

function MembersPage(props: RouteProps<PageData>) {
  const currentUser = useCurrentUser();
  const { state, setState } = props;
  const { courseId, userId, redirectToGuide } = props.route.params;
  const { selectedUser, course } = state;

  useEffect(() => {
    loadNextPage({
      courseId,
      setState,
    });
  }, [courseId]);

  /*
   * Redirects `/people` url to to the session user profile detail page
   * on desktop when the `userId` param is empty.
   */
  useEffect(() => {
    if (!userId && currentUser?.id) {
      router.rewrite(
        URLS.student.memberProfile({
          course,
          user: currentUser,
        }),
      );
    }
  }, []);

  /*
   * Redirects to the guide page when `redirectToGuide` is true.
   */
  useEffect(() => {
    if (redirectToGuide === 'true') {
      router.rewrite(
        URLS.student.memberProfile({
          course,
          user: {
            id: course.guide.id,
            name: course.guide.name,
          },
        }),
      );
    }
  }, [redirectToGuide]);

  useAsyncEffect(async () => {
    if (!userId) {
      setState((s) => ({ ...s, selectedUser: undefined }));
    } else if (userId !== selectedUser?.id) {
      const result = await rpx.members.getCourseMember({
        courseId,
        userId,
      });
      setState((s) => ({ ...s, selectedUser: result }));
    }
  }, [userId]);

  const onSearch = useMemo(
    () =>
      debounce(
        serialAsync(async (opts: { term: string; cursor?: string }) => {
          return await loadNextPage({
            courseId,
            searchTerm: opts.term,
            cursor: opts.cursor,
            setState,
          });
        }),
      ),
    [],
  );

  return (
    <StudentPage
      course={course}
      currentLink="people"
      editLink={{
        // Do not open student detail page if the user is active session user.
        // Because it'll be the guide in this case, and it won't be accessible
        // on the students page.
        url: `/manage/courses/${course.id}/students/${currentUser?.id !== userId ? userId : ''}`,
      }}
      sideNavContent={
        <MemberList
          loading={state.loading}
          hasMore={state.hasMore}
          users={state.users}
          guide={course.guide}
          cursor={state.cursor}
          onSearch={onSearch}
        />
      }
      documentTitle={selectedUser?.name}
    >
      {state.loading && <LoadingIndicator />}
      {selectedUser && <MemberProfile key={selectedUser.id} user={selectedUser} course={course} />}
    </StudentPage>
  );
}

function Page(props: RouteProps<PageData>) {
  if (props.data.course.hidePeople) {
    return <RedirectToCourse course={props.data.course} />;
  }

  return <MembersPage {...props} />;
}

router.add({
  url: 'courses/:courseId/people',
  render: Page,
  authLevel: 'student',
  load,
});

router.add({
  url: 'courses/:courseId/people/:userId',
  render: Page,
  authLevel: 'student',
  load,
});
