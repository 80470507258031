import { router } from '@components/router';
import { AppRoute } from 'client/lib/app-route/types';
import { LoadedProps } from 'client/lib/loaders';
import { rpx } from 'client/lib/rpx-client';
import { URLS } from 'shared/urls';
import { ProvisioningForm } from '@components/checkout';
import { CourseCheckoutPage, finalCheckoutPageURL } from '@components/course-checkout';
import { useGiftState } from './gift-form';
import { useIntl } from 'shared/intl/use-intl';

async function load(route: AppRoute) {
  return rpx.courseCheckout.getProvisioningState({ courseId: route.params.courseId });
}

type Props = LoadedProps<typeof load>;

function Page(props: Props) {
  const intl = useIntl();
  const { course, purchase } = props.data;
  const [giftState, setGiftState] = useGiftState(course);
  const giftEmailScheduledAt =
    giftState?.emailScheduledAt instanceof Date
      ? giftState.emailScheduledAt.toISOString()
      : giftState?.emailScheduledAt;
  const isGift = props.route.params.gift === 'true' || !!giftState?.email;

  const provision = async () => {
    const result = await rpx.courseCheckout.provision({
      courseId: course.id,
      purchaseId: purchase.id,
      giftMessage: giftState?.message,
      giftShouldSendEmail: giftState?.shouldSendEmail,
      giftEmailScheduledAt,
    });
    if (result.giftId) {
      setGiftState(undefined);
      return URLS.student.coursePage({
        course,
        page: `gifts/${result.giftId}/success?emailSent=${!!giftState?.shouldSendEmail}&emailDate=${
          giftEmailScheduledAt || ''
        }`,
      });
    }
    return finalCheckoutPageURL({
      id: course.id,
      title: course.title,
    });
  };

  return (
    <CourseCheckoutPage
      course={course}
      title={course.isProduct || isGift ? intl('Thank you!') : intl('Thank you for joining!')}
    >
      <ProvisioningForm purchase={purchase} provision={provision} />
    </CourseCheckoutPage>
  );
}

router.add({
  url: '/courses/:courseId/provision',
  load,
  render: Page,
});
