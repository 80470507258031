import { useRouteParams } from '@components/router';
import { CourseWithGuide, Member } from 'server/types';
import { UserProfileIcon } from '@components/avatars';
import { BtnSecondary } from '@components/buttons';
import { useState } from 'preact/hooks';
import { Pill } from '@components/pill';
import { SearchBox } from '@components/search-box';
import { useCurrentUser } from '@components/router/session-context';
import { Case } from '@components/conditional';
import { URLS } from 'shared/urls';
import { StudentPageNavHeader } from '@components/page-nav-bar';
import { useIntl } from 'shared/intl/use-intl';

interface ItemProps {
  isSessionUser?: boolean;
  user: Omit<Member, 'membershipId'>;
}

function ListItem({ user }: ItemProps) {
  const intl = useIntl();
  const { courseId, userId, courseSlug } = useRouteParams();
  const isSelected = userId === user.id;
  const pillText =
    user.level === 'guide'
      ? intl('Guide')
      : user.level === 'facilitator'
      ? intl('Facilitator')
      : '';

  return (
    <a
      href={URLS.student.memberProfile({
        course: {
          id: courseId,
          title: courseSlug,
        },
        user,
      })}
      class={`flex rounded-md relative w-full py-2 mb-2 items-center hover:text-indigo-600 dark:hover:text-sky-400 hover:theme-link ${
        isSelected
          ? 'text-indigo-600 theme-link dark:text-sky-400 font-semibold'
          : 'text-gray-500 dark:text-gray-400 font-normal'
      }`}
    >
      <UserProfileIcon user={user} size="w-8 h-8" />
      <span class="flex flex-col flex-1 min-w-0 justify-center ml-4">
        <span class="inline-flex items-center gap-2">
          {user.name}
          {pillText && <Pill>{pillText}</Pill>}
        </span>
      </span>
    </a>
  );
}

interface Props {
  users: Member[];
  hasMore: boolean;
  guide: CourseWithGuide['guide'];
  cursor?: string;
  loading: boolean;
  onSearch(opts: { term: string; cursor?: string }): void;
}

export function MemberList({ users, guide, hasMore, loading, cursor, onSearch }: Props) {
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const currentUser = useCurrentUser();

  return (
    <div class="flex flex-col grow pb-20">
      <StudentPageNavHeader title={intl('People')} />
      <div onClick={(e) => e.stopPropagation()} class="mb-6">
        <SearchBox
          id="search-members"
          placeholder={intl('Find someone...')}
          containerClass="bg-transparent"
          value={searchTerm}
          onTermChange={(term) => {
            setSearchTerm(term);
            onSearch({ term });
          }}
        />
      </div>
      {currentUser && (
        <Case when={searchTerm.length === 0}>
          <ListItem user={{ ...currentUser, level: 'student' }} isSessionUser />
          {currentUser.id !== guide.id && <ListItem user={{ ...guide, level: 'guide' }} />}
        </Case>
      )}
      {!users.length && !!searchTerm && (
        <p>
          {intl('No members found that match "{searchTerm:string}"', {
            searchTerm,
          })}
        </p>
      )}
      <div class="flex flex-col">
        {users.map((user) => {
          const isSessionUser = user.id === currentUser?.id;
          const isGuide = user.id === guide.id;

          if (searchTerm.length === 0 && (isSessionUser || isGuide)) {
            return null;
          }

          return <ListItem key={user.id} user={user} isSessionUser={isSessionUser} />;
        })}
      </div>
      {hasMore && (
        <div class="flex justify-center mt-4 mb-4">
          <BtnSecondary
            isLoading={loading}
            onClick={(e: any) => {
              e.stopPropagation();
              onSearch({ term: searchTerm, cursor });
            }}
          >
            {intl('Load More')}
          </BtnSecondary>
        </div>
      )}
    </div>
  );
}
