/**
 * A banner which we display on core Ruzuku to anyone who is a student in another tenant.
 */

import { useMemo, useRef } from 'preact/hooks';
import { IcoChevronDown } from '@components/icons';
import { Button } from '@components/buttons';
import { Data } from './types';
import { useIntl } from 'shared/intl/use-intl';
import { useLocalStorageState } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';

function TenantLink({
  count,
  domain,
  tenantId,
}: {
  domain: string;
  tenantId: string;
  count: number;
}) {
  const intl = useIntl();
  const href = useRef(`https://${domain}`);
  const [prefix, linkText, suffix] = intl.split(
    'View {count:number} {count | pluralize course courses} at <>{domain:string}</>.',
    {
      count,
      domain,
    },
  );
  return (
    <div>
      {prefix}
      <a
        class="underline text-indigo-600"
        href={href.current}
        target="_blank"
        rel="noreferrer"
        onClick={async (e) => {
          const anchor = e.target as HTMLAnchorElement;
          if (!href.current.endsWith(domain)) {
            return;
          }
          e.preventDefault();
          anchor.href = await rpx.auth.makeOneClickLink({ tenantId });
          href.current = anchor.href;
          anchor.click();
        }}
      >
        {linkText}
      </a>
      {suffix}
    </div>
  );
}

export function CrossTenantCoursesBanner(props: { tenants: Data['crossTenant'] }) {
  const intl = useIntl();
  const [state, setState] = useLocalStorageState('banner.tenantcourses', { collapse: false });
  const { tenants, numCourses } = useMemo(() => {
    const sorted = props.tenants.sort((a, b) => a.domain.localeCompare(b.domain));
    return { tenants: sorted, numCourses: sorted.reduce((a, b) => a + b.count, 0) };
  }, [props.tenants]);

  if (state.collapse) {
    return (
      <button
        onClick={() => setState({ collapse: false })}
        class="p-4 px-6 mb-8 bg-gray-50 border rounded-full flex items-center w-full hover:bg-gray-100 justify-between gap-6"
      >
        <span class="font-semibold">
          {intl('You have {count:number} more {count | pluralize course courses} available.', {
            count: numCourses,
          })}
        </span>
        <IcoChevronDown />
      </button>
    );
  }

  return (
    <section class="p-4 lg:p-8 sm:rounded-2xl bg-gradient-to-r from-violet-50/50 to-violet-50 sm:mb-8 border-indigo-200 border flex gap-6 mb-4">
      <span class="flex shrink-0 items-center justify-center font-serif rounded-full bg-indigo-600 text-white font-semibold text-2xl size-10 italic">
        i
      </span>
      <div class="flex flex-col">
        <h2 class="font-semibold text-xl">{intl(`Can't find what you're looking for?`)}</h2>
        <p>
          {intl(`Some of your courses may have moved. Follow the links below to see more courses:`)}
        </p>
        <div class="py-4 flex flex-col gap-4">
          {tenants.map((t) => (
            <TenantLink key={t.tenantId} tenantId={t.tenantId} count={t.count} domain={t.domain} />
          ))}
        </div>
        <footer class="mt-2">
          <Button
            type="button"
            onClick={() => setState({ collapse: true })}
            class="border border-indigo-600 text-indigo-600 rounded-full px-6 p-1 text-base inline-flex hover:bg-indigo-600 hover:text-white"
          >
            {intl('Dismiss')}
          </Button>
        </footer>
      </div>
    </section>
  );
}
