import { router } from '@components/router';
import { AppRoute } from 'client/lib/app-route/types';
import { LoadedProps } from 'client/lib/loaders';
import { rpx } from 'client/lib/rpx-client';
import { AuthForm, CheckoutForm } from '@components/checkout';
import { intl } from 'shared/intl/use-intl';
import { useCurrentUser } from 'client/lib/auth';
import { setUrlSearchParam } from 'client/utils/url';
import {
  CourseCheckoutErrorPage,
  CourseCheckoutPage,
  redirectToCourse,
} from '@components/course-checkout';

async function load(route: AppRoute) {
  const { courseId } = route.params;
  const giftCode = route.params.gift;
  const result = await rpx.gifts.getGiftState({
    courseId,
    gift: giftCode,
  });

  // If the session user is the owner of the gift, we redirect to the gifts tab.
  if (result.isOwner) {
    router.goto('/account/gifts');
  }

  return {
    course: { ...result.course, subcourses: result.subcourses },
    giftCode,
    gift: result.gift,
  };
}

type Props = LoadedProps<typeof load>;

function Page(props: Props) {
  const user = useCurrentUser();
  const { course, gift, giftCode } = props.data;
  const acceptGift = async () => {
    await rpx.gifts.acceptGift({ courseId: course.id, giftCode });
    await redirectToCourse(course);
  };

  if (gift.toUserId) {
    return (
      <CourseCheckoutErrorPage course={course} title={intl('Gift used')}>
        {intl('This gift has already been accepted.')}
      </CourseCheckoutErrorPage>
    );
  }

  return (
    <CourseCheckoutPage
      course={course}
      title={course.isBundle ? intl('Join courses') : intl('Join course')}
      showHeader={false}
      subtitle={
        course.isBundle
          ? intl('These courses have been gifted to you!')
          : intl('This course has been gifted to you!')
      }
    >
      {!user && (
        <AuthForm
          title={intl('Accept gift')}
          finalActionText={intl('Accept Gift ⤑')}
          subtitle={intl('To accept this gift, enter your email address.')}
          onSubmit={async () => {
            router.goto(location.pathname + setUrlSearchParam('auto', 'true').toString());
          }}
        />
      )}
      {!!user && (
        <CheckoutForm
          autoSubmit={props.route.params.auto === 'true'}
          actionText={intl('Accept Gift ⤑')}
          onSubmit={acceptGift}
        ></CheckoutForm>
      )}
    </CourseCheckoutPage>
  );
}

router.add({
  isPublic: true,
  load,
  url: '/courses/:courseId/gifts/accept/:gift',
  render: Page,
});
