/**
 * This module contains all of the state management logic for the lesson editor.
 *
 * Loading here has similar requirements to the lesson view, and we probably should
 * consider whether the commonalities should be shared or not.
 */

import { insertUnique } from 'shared/utils';
import { StateUpdater, Dispatch } from 'preact/hooks';
import { RpxResponse, modulesService, rpx } from 'client/lib/rpx-client';
import { showError } from '@components/app-error';
import { Course, Lesson, ManageState, Module } from './types';
import { captureException } from 'client/lib/sentry';
import { DragState } from '@components/draggable';
import { router } from '@components/router';
import { groupBy } from 'shared/utils';
import { fixupModule } from 'client/lib/rpx-client/modules-service';

type Outline = Pick<RpxResponse<typeof rpx.lessons.getFullLessonState>, 'lessons' | 'modules'> & {
  courseId: string;
};

export type SetState = Dispatch<StateUpdater<ManageState>>;

/**
 * Delete the lesson.
 */
export async function deleteLesson(setState: SetState, opts: { courseId: UUID; lessonId: UUID }) {
  await rpx.lessons.deleteLesson({ id: opts.lessonId, courseId: opts.courseId });
  setState((state) => {
    const lesson = state.lessons[opts.lessonId];
    const module = state.modules[lesson.moduleId];
    return {
      ...state,
      modules: {
        ...state.modules,
        [module.id]: { ...module, lessons: module.lessons.filter((x) => x !== lesson.id) },
      },
    };
  });
}

/**
 * Load a full lesson.
 */
export async function loadLesson(setState: SetState, opts: { courseId: UUID; lessonId: UUID }) {
  setState((state) => ({ ...state, loadingLesson: opts.lessonId }));
  const lesson = await rpx.lessons.getLesson({ courseId: opts.courseId, lessonId: opts.lessonId });
  setState((state) => {
    // If the user navigated to a different lesson, then we won't mess with loading indicator.
    const isStale = state.loadingLesson !== lesson.id;
    if (isStale) {
      return state;
    }
    return {
      ...state,
      loadingLesson: undefined,
      lessonId: lesson.id,
      lessons: { ...state.lessons, [lesson.id]: { ...lesson, type: 'full' } },
    };
  });
}

export function deletedModule(setState: SetState, id: UUID) {
  return setState((state) => {
    const module = state.modules[id];
    const course = state.courses[module.courseId];
    const modules = course.modules.filter((x) => x !== module.id);
    return {
      ...state,
      moduleId: undefined,
      courses: { ...state.courses, [module.courseId]: { ...course, modules } },
    };
  });
}

/**
 * Update the module.
 */
export async function updateModule(
  setState: SetState,
  state: Pick<ManageState, 'courseId' | 'courses' | 'modules'>,
  updates: Pick<Module, 'id' | 'title' | 'startOffset' | 'prices' | 'startDate' | 'isDraft'>,
) {
  const course = getCurrentCourse(state);
  const module = state.modules[updates.id];
  let moduleIds = course.modules;

  // If we have an ondemand or scheduled course, and the date / day has changed,
  // we need to reorder the modules.
  const isReorderable = course.accessFormat === 'ondemand' || course.accessFormat === 'scheduled';
  const shouldReorder =
    module.startDate?.toISOString() !== updates.startDate?.toISOString() ||
    module.startOffset !== updates.startOffset;
  if (isReorderable && shouldReorder) {
    moduleIds = course.modules
      .map((id) => {
        const module = state.modules[id];
        return id === updates.id ? { ...module, ...updates } : module;
      })
      .sort((a, b) => {
        const MAX_INT = Number.MAX_SAFE_INTEGER;
        const [val1, val2] =
          course.accessFormat === 'ondemand'
            ? [a.startOffset || MAX_INT, b.startOffset || MAX_INT]
            : [a.startDate?.getTime() || MAX_INT, b.startDate?.getTime() || MAX_INT];
        if (val1 === val2) {
          return a.seq! > b.seq! ? 1 : -1;
        }
        return val1 > val2 ? 1 : -1;
      })
      .map((l) => l.id);

    await modulesService.reorderModules({
      isAbsoluteSchedule: course.isAbsoluteSchedule,
      courseId: course.id,
      moduleId: updates.id,
      moduleIds,
      startOffset: updates.startOffset,
      startDate: updates.startDate || module.startDate,
    });
  }

  await modulesService.updateModule({
    isAbsoluteSchedule: course.isAbsoluteSchedule,
    id: updates.id,
    title: updates.title,
    startDate: updates.startDate,
    prices: updates.prices,
    isDraft: updates.isDraft,
  });

  setState((state) => {
    const module = { ...state.modules[updates.id], ...updates };
    const updatedCourse = { ...state.courses[course.id], modules: moduleIds };
    const modules = { ...state.modules, [updates.id]: module };
    moduleIds.forEach((id, seq) => {
      modules[id] = { ...modules[id], seq };
    });
    return {
      ...state,
      moduleId: undefined,
      modules,
      courses: { ...state.courses, [course.id]: updatedCourse },
    };
  });
}

export async function loadState(
  setState: SetState,
  opts: {
    currentLessonId: UUID | undefined;
    courseId: UUID;
    lessonId: UUID;
  },
) {
  try {
    if (opts.lessonId && opts.currentLessonId !== opts.lessonId) {
      await loadLesson(setState, opts);
    } else if (!opts.lessonId) {
      setState((s) => ({ ...s, lessonId: undefined }));
    }
  } catch (err) {
    showError(err);
  }
}

/**
 * Save a lesson.
 */
export async function saveLesson(
  setState: SetState,
  opts: {
    courseId: UUID;
    lesson: Omit<Lesson, 'moduleId' | 'type' | 'isAvailable'>;
  },
) {
  const { courseId, lesson } = opts;
  try {
    await rpx.lessons.saveLesson({
      id: lesson.id,
      courseId,
      title: lesson.title,
      content: lesson.content || '',
      discussion: lesson.discussion,
      downloads: lesson.downloads,
      isPrerequisite: lesson.isPrerequisite,
      assessmentType: lesson.assessmentType,
      mediaFiles: lesson.mediaFiles,
    });
    setState((state) => {
      return {
        ...state,
        lessons: {
          ...state.lessons,
          [lesson.id]: Object.assign({}, state.lessons[lesson.id], lesson),
        },
      };
    });
  } catch (err) {
    showError(err);

    // A validation error is not expected here as all arguments either have a default
    // or they are optional. So this error usually means something else is wrong
    // and we are throwing an exception to make sure this session is logged in Sentry Replay.
    if (err.type === 'validation') {
      captureException(new Error('Unexpected validation error'));
    }
  }
}

export async function refreshOutline(setState: SetState, props: { courseId: string }) {
  const { courseId } = props;
  try {
    const fullLesson = await rpx.lessons.getFullLessonState({ courseId });
    setState((state) => {
      const course = state.courses[courseId];
      const outline = normalizeOutline({ ...fullLesson, course });
      const lesson = state.lessonId ? state.lessons[state.lessonId] : undefined;
      if (!course) {
        return state;
      }
      if (lesson) {
        // Preserve the full lesson, if we have it...
        outline.lessons[lesson.id] = lesson;
      }
      return {
        ...state,
        modules: outline.modules,
        lessons: outline.lessons,
        courses: { ...state.courses, [course.id]: { ...course, modules: outline.moduleIds } },
        orderedLessonIds: outline.orderedLessonIds,
      };
    });
  } catch (err) {
    showError(err);
  }
}

export function normalizeOutline({
  lessons,
  modules,
  course,
}: Omit<Outline, 'courseId'> & { course: Pick<Course, 'isAbsoluteSchedule' | 'id'> }) {
  const moduleIds = modules.map((s) => s.id);
  const moduleLessons = groupBy((x) => x.moduleId, lessons);
  const modulesMap = modules.reduce((acc, s) => {
    acc[s.id] = fixupModule({
      ...s,
      courseId: course.id,
      lessons: moduleLessons[s.id]?.map((x) => x.id) || [],
      isDraft: s.isDraft,
      isAbsoluteSchedule: course.isAbsoluteSchedule,
    });
    return acc;
  }, {} as ManageState['modules']);

  return {
    moduleIds,
    orderedLessonIds: moduleIds.flatMap((moduleId) => modulesMap[moduleId].lessons),
    lessons: lessons.reduce((acc, s) => {
      acc[s.id] = {
        ...s,
        type: 'partial',
      };
      return acc;
    }, {} as Record<UUID, Lesson>),
    modules: modulesMap,
  };
}

/**
 * Load the initial state.
 */
export async function load(
  courseId: UUID,
  lessonId: UUID | undefined,
): Promise<ManageState & { lastViewedLessonId?: UUID }> {
  const [course, result] = await Promise.all([
    rpx.courses.getGuideCourse({ id: courseId }),
    rpx.lessons.getFullLessonState({ courseId, lessonId }),
  ]);

  const outline = normalizeOutline({
    course,
    lessons: result.lessons,
    modules: result.modules,
  });

  if (result.lesson) {
    outline.lessons[result.lesson.id] = {
      type: 'full',
      ...result.lesson,
    };
  }

  return {
    courseId,
    lessonId,
    lastViewedLessonId: result.lastViewedLesson,
    membershipDate: result.membershipDate,
    completedLessons: result.completedLessons,
    orderedLessonIds: outline.orderedLessonIds,
    courses: {
      [courseId]: {
        ...course,
        modules: outline.moduleIds,
      },
    },
    isLoading: false,
    lessons: outline.lessons,
    modules: outline.modules,
    accessLevel: result.accessLevel,
  };
}

/**
 * The current course.
 */
export const getCurrentCourse = (state: Pick<ManageState, 'courseId' | 'courses'>) =>
  state.courses[state.courseId];

/**
 * The current lesson.
 */
export const getCurrentLesson = (state: ManageState) => {
  if (!state.lessonId) {
    return undefined;
  }
  const lesson = state.lessons[state.lessonId];
  if (lesson.type !== 'full') {
    return undefined;
  }
  return lesson;
};

/**
 * Insert a new module.
 */
export async function insertModule(
  setState: SetState,
  opts: {
    modules: ManageState['modules'];
    course: Course;
  },
) {
  const { course, modules } = opts;
  setState((state) => ({ ...state, adding: { type: 'module' } }));

  try {
    const title = '';
    const courseId = course.id;
    const prev = modules[course.modules[course.modules.length - 1]];
    const schedule: Pick<Module, 'startDate' | 'startOffset'> = {};

    if (course.accessFormat === 'scheduled') {
      const prevDate = new Date(prev?.startDate || new Date());
      prevDate.setDate(prevDate.getDate() + 1);
      schedule.startDate = prevDate;
    } else if (course.accessFormat === 'ondemand') {
      schedule.startOffset = prev ? (prev.startOffset || 0) + 24 * 60 : 0;
    }

    // Create a new module
    const moduleId = await modulesService.createModule({
      isAbsoluteSchedule: course.isAbsoluteSchedule,
      title,
      courseId,
      ...schedule,
    });

    // Compute the correct module ordering
    const moduleIds = [...course.modules, moduleId];
    // Save the module ordering
    await modulesService.reorderModules({
      isAbsoluteSchedule: course.isAbsoluteSchedule,
      courseId,
      moduleIds,
      moduleId,
      ...schedule,
    });

    setState((state) => {
      const updatedModule = {
        id: moduleId,
        title,
        courseId: course.id,
        lessons: [],
        isDraft: false,
        ...schedule,
      };
      return {
        ...state,
        adding: undefined,
        moduleId,
        modules: { ...state.modules, [moduleId]: updatedModule },
        courses: {
          ...state.courses,
          [course.id]: { ...state.courses[course.id], modules: moduleIds },
        },
      };
    });
  } catch (err) {
    showError(err);
  }
}

/**
 * Insert a new lesson.
 */
export async function insertLesson(setState: SetState, module: Module) {
  setState((state) => ({ ...state, adding: { type: 'lesson', moduleId: module.id } }));

  try {
    const lesson = await rpx.lessons.createLesson({
      moduleId: module.id,
      title: '',
      content: '',
      seq: 0,
    });
    const lessonIds = [...module.lessons, lesson.id];
    await rpx.modules.reorderLessons({
      moduleId: module.id,
      lessonIds,
    });
    setState((state) => ({
      ...state,
      adding: undefined,
      modules: {
        ...state.modules,
        [lesson.moduleId]: { ...state.modules[lesson.moduleId], lessons: lessonIds },
      },
      lessons: { ...state.lessons, [lesson.id]: { ...(lesson as any), type: 'full' } },
    }));

    // The setTimeout is a hacky way to prevent this from conflicting with other state
    // and layout issues. TODO: investigate why...
    setTimeout(() => router.goto(`/manage/courses/${module.courseId}/lessons/${lesson.id}`));
  } catch (err) {
    showError(err);
  }
}

function autoAdjustModuleSchedule(
  moduleId: UUID,
  state: ManageState,
): Pick<Module, 'startDate' | 'startOffset'> {
  const course = getCurrentCourse(state);
  const i = course.modules.indexOf(moduleId);
  const module = state.modules[course.modules[i]];
  const prevModule = state.modules[course.modules[i - 1]];
  const nextModule = state.modules[course.modules[i + 1]];

  // Do not modify the start date or offset if the module
  // has the same start date as the next module
  // because this is just the re-ordering of modules scheduled
  // for the same date.
  if (module?.startDate && nextModule?.startDate) {
    if (module.startDate.getTime() === nextModule.startDate.getTime()) {
      return {
        startDate: module.startDate,
        startOffset: module.startOffset,
      };
    }
  }
  if (module?.startOffset !== undefined && nextModule?.startOffset !== undefined) {
    if (module.startOffset === nextModule.startOffset) {
      return {
        startDate: module.startDate,
        startOffset: module.startOffset,
      };
    }
  }

  // If we've dropped the module between two other modules, we'll schedule
  // our dropped module to be halfway between them.
  return {
    startDate: prevModule?.startDate ?? nextModule?.startDate ?? module?.startDate,
    startOffset: prevModule?.startOffset ?? nextModule?.startOffset ?? (module?.startOffset || 0),
  };
}

/**
 * When the drag operation completes, we need to persist the
 * new world order.
 */
export function persistDragOrder(setState: SetState, state: ManageState, dragState: DragState) {
  const id = dragState.dragging.id;
  if (dragState.dragging.table === 'lessons') {
    const lesson = state.lessons[id];
    const module = state.modules[lesson.moduleId];
    return rpx.modules.reorderLessons({
      moduleId: module.id,
      lessonIds: module.lessons,
    });
  }

  // We're reordering modules
  const course = getCurrentCourse(state);
  // We need to make date adjustments, if this is a scheduled / ondemand course.
  const schedule =
    course.accessFormat === 'ondemand' || course.accessFormat === 'scheduled'
      ? autoAdjustModuleSchedule(id, state)
      : {};

  setState((state) => {
    const updates = { ...schedule, id };
    const module = {
      ...state.modules[updates.id],
      startDate: updates.startDate,
      startOffset: updates.startOffset,
    };
    return {
      ...state,
      modules: { ...state.modules, [updates.id]: module },
    };
  });

  return modulesService.reorderModules({
    isAbsoluteSchedule: course.isAbsoluteSchedule,
    courseId: course.id,
    moduleIds: course.modules,
    moduleId: id,
    startOffset: schedule.startOffset,
    startDate: schedule.startDate,
  });
}

/**
 * This convoluted bit of magic reorders lessons or modules
 * based on the drag state. This really could do with some tidying up.
 */
export function dragReorder(setState: SetState, dragState: DragState) {
  setState((state) => {
    // Reorder modules
    if (dragState.dragging.table === 'modules') {
      const course = getCurrentCourse(state);
      return {
        ...state,
        courses: {
          ...state.courses,
          [course.id]: {
            ...course,
            modules: insertUnique(
              dragState.dragging.id,
              dragState.direction === 'after' ? 'after' : 'before',
              dragState.target.id,
              course.modules,
            ),
          },
        },
      };
    }

    // Reorder lessons
    const dragging = state.lessons[dragState.dragging.id];
    const module = state.modules[dragging.moduleId];

    // We're dragging our lesson over another lesson
    if (dragState.target.table === 'lessons') {
      return {
        ...state,
        modules: {
          ...state.modules,
          [module.id]: {
            ...module,
            lessons: insertUnique(
              dragState.dragging.id,
              dragState.direction === 'after' ? 'after' : 'before',
              dragState.target.id,
              module.lessons,
            ),
          },
        },
      };
    }

    // We're dragging our lesson over its own module, so this is a noop.
    if (dragState.target.table === 'modules' && dragState.target.id === module.id) {
      return state;
    }

    // We're dragging our lesson over a new module, so we need to move it.
    const targetModule = state.modules[dragState.target.id];

    return {
      ...state,
      lessons: { ...state.lessons, [dragging.id]: { ...dragging, moduleId: targetModule.id } },
      modules: {
        ...state.modules,
        [module.id]: {
          ...module,

          // Remove the lesson from its old module
          lessons: module.lessons.filter((l) => l !== dragging.id),
        },
        [targetModule.id]: {
          ...targetModule,
          // Add the lesson to its new module
          lessons:
            dragState.direction === 'before'
              ? targetModule.lessons.concat(dragging.id)
              : [dragging.id, ...targetModule.lessons],
        },
      },
    };
  });
}

/**
 * Edit the specified module. If undefined, this clears the module editor.
 */
export function editModule(setState: SetState, moduleId: UUID) {
  return setState((state) => ({ ...state, moduleId }));
}
