import { Breadcrumbs, Crumb } from '@components/breadcrumbs';
import { Course } from 'server/types';

interface Props {
  course?: Pick<Course, 'id' | 'title'>;
  crumbs?: Array<Crumb | undefined>;
}

export function CourseBreadcrumbs({ course, crumbs }: Props) {
  return (
    <Breadcrumbs
      crumbs={[
        { href: crumbs?.length ? '/courses' : '', text: 'My courses' },
        {
          href: course && crumbs?.length ? `/manage/courses/${course.id}` : '',
          text: (course && course.title) || ' ',
        },
        ...(crumbs || []),
      ]}
    />
  );
}
