import { router } from '@components/router';
import { AppRoute } from 'client/lib/app-route/types';
import { LoadedProps } from 'client/lib/loaders';
import { rpx } from 'client/lib/rpx-client';
import { AuthForm, CheckoutForm } from '@components/checkout';
import { intl } from 'shared/intl/use-intl';
import { useCurrentUser } from 'client/lib/auth';
import { setUrlSearchParam } from 'client/utils/url';
import {
  CourseCheckoutPage,
  CourseCheckoutErrorPage,
  redirectToCourse,
} from '@components/course-checkout';

async function load(route: AppRoute) {
  const { courseId } = route.params;
  const invitationString = route.params.invitation;
  const result = await rpx.invitations.getInvitationState({
    courseId,
    invitation: invitationString,
  });

  if (result.isMember) {
    // If there is an authenticated user when we load the page, and the user
    // is already a member, we try to finish and redirect here.
    await redirectToCourse(result.course);
  }

  return {
    ...result,
    invitationString,
    course: {
      ...result.course,
      subcourses: result.subcourses,
    },
  };
}

type Props = LoadedProps<typeof load>;

function Page(props: Props) {
  const user = useCurrentUser();
  const { course, isFull, invitation, invitationString } = props.data;
  const actionText = intl('Accept Invitation ⤑');
  const acceptInvitation = async () => {
    await rpx.invitations.acceptInvitation({
      courseId: course.id,
      invitation: invitationString,
    });
    await redirectToCourse(course);
  };

  if (invitation.userId) {
    return (
      <CourseCheckoutErrorPage course={course} title={intl('Invitation used')}>
        {intl('This invitation has already been accepted.')}
      </CourseCheckoutErrorPage>
    );
  }

  if (isFull) {
    return (
      <CourseCheckoutErrorPage course={course} title={intl('Course full')}>
        {intl('This course is full.')}
      </CourseCheckoutErrorPage>
    );
  }

  return (
    <CourseCheckoutPage
      course={course}
      title={course.isBundle ? intl('Join courses') : intl('Join course')}
      showHeader={false}
      subtitle={
        course.isBundle
          ? intl(`You've been invited to join these courses!`)
          : intl(`You've been invited to join this course!`)
      }
    >
      {!user && (
        <AuthForm
          title={intl('Accept invitation')}
          subtitle={intl('To accept this invitation, enter your email address.')}
          finalActionText={actionText}
          onSubmit={async () => {
            router.goto(location.pathname + setUrlSearchParam('auto', 'true').toString());
          }}
        />
      )}
      {!!user && (
        <CheckoutForm
          autoSubmit={props.route.params.auto === 'true'}
          actionText={actionText}
          onSubmit={acceptInvitation}
        ></CheckoutForm>
      )}
    </CourseCheckoutPage>
  );
}

router.add({
  isPublic: true,
  load,
  url: '/courses/:courseId/invitations/:invitation',
  render: Page,
});
