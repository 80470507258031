import { router, useRouteParams } from '@components/router';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { ruzcal, toQueryString } from 'shared/urls';
import { LoadedProps } from 'client/lib/loaders';
import { AppRoute } from 'client/lib/app-route/types';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { IcoArrowLeft, IcoArrowRight } from '@components/icons';
import { MeetingSummary } from './meeting-summary';
import { AsyncForm } from '@components/async-form';
import { Field, InputField, eventToState } from './form-helpers';
import { AutosizeText } from '@components/autosize-text';
import { BtnPrimary } from '@components/buttons';
import { ScheduleBooking } from './schedule-picker';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';

type Pane = 'date' | 'confirm';

function parseRouteSchedule(params: Record<string, string>) {
  const { start, end } = params;
  if (!start || !end) {
    return;
  }
  try {
    return {
      start: new Date(start),
      end: new Date(end),
    };
  } catch (err) {
    console.error(err);
  }
}

type LoadedState = RpxResponse<typeof rpx.ruzcal.getInitialBookingState> & {
  error: undefined;
  schedule?: { start: Date; end: Date };
  baseUrl: string;
  attendeeTimeZone: string;
  attendeeName: string;
  attendeeEmail: string;
  attendeeNotes: string;
  hour12: boolean;
};

type ErrorState = { type: 'error'; error: Error };

type State = LoadedState | ErrorState;

async function load(route: AppRoute): Promise<State> {
  const { urlPrefix, urlSuffix } = route.params;
  try {
    const state = await rpx.ruzcal.getInitialBookingState({
      urlPrefix,
      urlSuffix,
    });
    const schedule = parseRouteSchedule(route.params);

    const hour12 = localStorage.ruzcalHour12
      ? localStorage.ruzcalHour12 === 'true'
      : route.params.hour12 !== 'false';

    const attendeeTimeZone =
      route.auth.user?.timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone ||
      state.availability.timezone;

    return {
      ...state,
      error: undefined,
      schedule,
      baseUrl: ruzcal.newBookingUrl({ urlPrefix, urlSuffix }),
      attendeeTimeZone,
      attendeeName: '',
      attendeeEmail: '',
      attendeeNotes: '',
      hour12,
    };
  } catch (err) {
    console.error(err);
    return {
      type: 'error',
      error: err,
    };
  }
}

type Props = LoadedProps<typeof load>;

function paneHref(opts: { pane: Pane; schedule: LoadedState['schedule']; hour12: boolean }) {
  const { pane, schedule, hour12 } = opts;
  return toQueryString(
    {
      start: schedule?.start.toISOString(),
      end: schedule?.end.toISOString(),
      hour12: String(hour12),
      pane: schedule ? pane : 'date',
    },
    '?',
  );
}

function ConfirmBooking(props: { state: LoadedState; setState: Props['setState'] }) {
  const { state } = props;
  const { schedule, eventType, host } = state;

  if (!schedule) {
    return null;
  }

  return (
    <div class="p-2 flex items-center justify-center bg-gray-100 min-h-screen">
      <section class="p-8 bg-white rounded-2xl max-w-full w-3xl an-scale-in flex-col gap-8 grid sm:grid-cols-2">
        <div>
          <a
            class="inline-flex gap-2 items-center mb-4 font-medium"
            href={paneHref({
              pane: 'date',
              schedule,
              hour12: state.hour12,
            })}
          >
            <IcoArrowLeft />
            Back
          </a>
          <MeetingSummary
            host={host}
            name={eventType.name}
            description={eventType.description}
            duration={eventType.duration}
            location={eventType.location}
            locationDetail={eventType.locationDetail}
            timeZone={state.attendeeTimeZone}
            hour12={state.hour12}
            schedule={schedule}
          />
        </div>
        <AsyncForm
          class="flex flex-col gap-4 border-t pt-8 sm:pt-0 sm:border-0 sm:border-l sm:pl-8"
          onSubmit={async () => {
            const result = await rpx.ruzcal.saveEvent({
              end: schedule.end,
              start: schedule.start,
              eventTypeId: eventType.id,
              attendeeName: state.attendeeName,
              attendeeEmail: state.attendeeEmail,
              attendeeNotes: state.attendeeNotes,
              attendeeTimezone: state.attendeeTimeZone,
            });
            router.goto(
              ruzcal.existingBookingUrl({ id: result.id, token: result.token, success: true }),
            );
          }}
        >
          <InputField
            name="attendeeName"
            title="Your name"
            fullWidth
            autoFocus
            value={state.attendeeName}
            onInput={eventToState(props.setState)}
          />
          <InputField
            name="attendeeEmail"
            fullWidth
            title="Email address"
            value={state.attendeeEmail}
            onInput={eventToState(props.setState)}
          />
          <Field name="notes" title="Additional notes">
            <AutosizeText
              name="attendeeNotes"
              class="ruz-input min-h-20 p-2 px-3"
              value={state.attendeeNotes}
              onInput={eventToState(props.setState)}
            />
          </Field>
          <footer class="mt-4">
            <BtnPrimary class="inline-flex text-left gap-2 items-center p-2 px-4 rounded-full text-base">
              Schedule meeting
              <IcoArrowRight class="size-4 shrink-0" />
            </BtnPrimary>
          </footer>
        </AsyncForm>
      </section>
    </div>
  );
}

function LoadedPage(props: { state: LoadedState; setState: Props['setState'] }) {
  const params = useRouteParams();
  const { state, setState } = props;
  const { eventType } = props.state;
  const pane: Pane = params.pane !== 'date' && params.start ? 'confirm' : 'date';

  useDocumentTitle([eventType.name]);

  useDidUpdateEffect(() => {
    localStorage.ruzcalHour12 = state.hour12;
  }, [state.hour12]);

  if (pane === 'confirm' && state.schedule) {
    // This is a hack to make sure the schedule is set correctly.
    const schedule = parseRouteSchedule(params);
    state.schedule = schedule;
    return <ConfirmBooking state={state} setState={setState} />;
  }

  return (
    <div class="p-2 flex items-center justify-center bg-gray-100 min-h-screen an-scale-in">
      <section class="p-8 bg-white rounded-2xl max-w-full w-5xl">
        <div class="grid sm:grid-cols-2 lg:grid-cols-4 sm:gap-6 gap-10">
          <section>
            <MeetingSummary
              host={state.host}
              name={eventType.name}
              description={eventType.description}
              duration={eventType.duration}
              location={eventType.location}
              locationDetail={eventType.locationDetail}
              timeZone={state.attendeeTimeZone}
              hour12={state.hour12}
            />
          </section>
          <ScheduleBooking
            eventType={eventType}
            availability={state.availability}
            attendeeTimeZone={state.attendeeTimeZone}
            schedule={state.schedule}
            hour12={state.hour12}
            onScheduleChange={(schedule) => setState((s) => ({ ...s, schedule }))}
            onHour12Change={(hour12) => setState((s) => ({ ...s, hour12 }))}
            makeHref={(opts) =>
              paneHref({ pane: 'confirm', schedule: opts.schedule, hour12: state.hour12 })
            }
          />
        </div>
      </section>
    </div>
  );
}

export function Page(props: LoadedProps<typeof load>) {
  if (props.state.error) {
    return (
      <div class="flex flex-col gap-8 items-center justify-center bg-gray-100 min-h-screen text-center">
        <div class="text-lg">
          <h1 class="text-2xl font-semibold">Whoops!</h1>
          {(props.state.error as Error & { statusCode?: number }).statusCode === 404 ? (
            <p>We couldn't find the booking page you were looking for.</p>
          ) : (
            <p>Something went wrong.</p>
          )}
        </div>
        <footer>
          <BtnPrimary href="/" class="inline-flex gap-2 items-center rounded-full">
            <IcoArrowLeft />
            Go to home page
          </BtnPrimary>
        </footer>
      </div>
    );
  }
  return <LoadedPage state={props.state} setState={props.setState} />;
}

router.add({
  isPublic: true,
  url: 'cal/:urlPrefix/:urlSuffix',
  load,
  render: Page,
});
