import { StateUpdater } from 'preact/hooks';
import { Dispatch } from 'client/lib/hooks';
import { Meeting } from 'server/types';
import { AppRoute } from 'client/lib/app-route/types';
import { rpx } from 'client/lib/rpx-client';
import { LoadedProps } from 'client/lib/loaders';

export async function load(route: AppRoute) {
  const { courseId } = route.params;
  const [course, meetings] = await Promise.all([
    rpx.courses.getGuideCourse({ id: courseId }),
    rpx.meetings.getMeetings({ courseId }),
  ]);

  return {
    course,
    meetings,
  };
}

export type State = LoadedProps<typeof load>['state'];

type SetState = Dispatch<StateUpdater<State>>;

export function addMeeting(setState: SetState, meeting: Meeting) {
  setState((state) => {
    const meetings = [...state.meetings];
    meetings.push(meeting);
    meetings.sort((a, b) => {
      if (a.scheduledAt === b.scheduledAt) {
        return 0;
      }
      if (!a.scheduledAt) {
        return -1;
      }
      if (!b.scheduledAt) {
        return 1;
      }
      return a.scheduledAt > b.scheduledAt ? 1 : -1;
    });
    return { ...state, meetings };
  });
}

export function updateMeeting(setState: SetState, id: UUID, meeting: Partial<Meeting>) {
  setState((state) => {
    const meetings = [...state.meetings];
    const index = meetings.findIndex((w) => w.id === id);
    meetings[index] = { ...meetings[index], ...meeting };
    return { ...state, meetings };
  });
}

export function deleteMeeting(setState: SetState, id: UUID) {
  setState((state) => {
    return { ...state, meetings: state.meetings.filter((w) => w.id !== id) };
  });
}
