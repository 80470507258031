import { useAsyncData, useTryAsyncData } from 'client/lib/hooks';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { DefaultSpinner } from '@components/spinner';
import { PurchasePane } from '../pmts/components/purchase-pane';
import { CorePricingTable } from '../pmts/components/core-pricing-table';
import { useState } from 'preact/hooks';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { AccountTabContent } from './account-tab-content';
import { useImageUrl } from 'client/utils/cdn';
import * as dtfmt from 'shared/dateutil';
import { PriceSummary } from '../pmts/components/price-summary';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { BtnSecondary } from '@components/buttons';
import { showError } from '@components/app-error';
import { router, useRouteParams } from '@components/router';
import { SlideOver } from '@components/slide-over';
import { PaymentPane } from '../student-course-preferences';
import { hasLevel } from 'shared/auth';
import { IcoRuzukuLogo } from '@components/icons';
import { useIntl } from 'shared/intl/use-intl';

type PurchaseState = {
  isInitializing: boolean;
  isLoading: boolean;
  cursor?: number;
  purchases: RpxResponse<typeof rpx.accounts.getMyProductPurchases>['purchases'];
};

function CourseImage({ src }: { src: undefined | string }) {
  const imageUrl = useImageUrl(src);
  return (
    <span class="flex items-center justify-center text-center w-12 min-w-12 h-12 bg-gray-100 border-b overflow-hidden rounded shadow">
      {imageUrl && <img src={imageUrl} loading="lazy" class="object-cover" />}
      {!imageUrl && <span class="opacity-50 text-xs"></span>}
    </span>
  );
}

function CoursePurchaseItem({ purchase, price, coupon }: PurchaseState['purchases'][0]) {
  if (!price) {
    return null;
  }

  return (
    <a
      href={`?courseId=${purchase.courseId}`}
      class="rounded flex items-center gap-4 p-2 text-inherit hover:bg-gray-50"
    >
      <CourseImage src={purchase.imagePath} />
      <span class="flex flex-col">
        <strong>{purchase.courseTitle}</strong>
        <span class="flex items-center gap-2 text-gray-500">
          <span>{dtfmt.compactDate(purchase.createdAt)}</span>
          {' • '}
          <span>
            <PriceSummary price={price} coupon={coupon} multiline={false} />
          </span>
          {' • '}
          <span class={purchase.status === 'active' ? 'text-green-600' : 'text-gray-500'}>
            {purchase.status}
          </span>
        </span>
      </span>
    </a>
  );
}

function CoursePurchaseDetails({ courseId }: { courseId: string }) {
  const course = useTryAsyncData(
    () => rpx.courses.getPurchasedCourse({ id: courseId }),
    [courseId],
  );
  return (
    <SlideOver close={() => router.goto(location.pathname)}>
      {course.data && (
        <header class="flex items-center gap-4">
          <CourseImage src={course.data?.imagePath} />
          <strong>{course.data.title}</strong>
        </header>
      )}
      <PaymentPane courseId={courseId} />
    </SlideOver>
  );
}

function RuzukuBillingItem() {
  const user = useCurrentUser();
  return (
    <>
      <h2 class="font-semibold">Your Ruzuku Account</h2>
      <a
        href={`?coreBilling=true`}
        class="rounded flex items-center gap-4 p-2 text-inherit hover:bg-gray-50"
      >
        <span class="flex items-center justify-center text-center w-12 min-w-12 h-12 bg-gray-100 border-b overflow-hidden rounded shadow p-2">
          <IcoRuzukuLogo />
        </span>
        <span class="flex flex-col">
          <strong>Manage your Ruzuku billing</strong>
          <span class="flex items-center text-gray-500 capitalize">Tier: {user?.tier}</span>
        </span>
      </a>
    </>
  );
}

export function BillingTab() {
  const intl = useIntl();
  const user = useCurrentUser();
  const tenant = useCurrentTenant();
  const displayBilling = hasLevel(user, tenant.isCore ? 'guide' : 'admin');
  const { courseId, coreBilling } = useRouteParams();
  const [state, setState] = useState<PurchaseState>(() => ({
    isLoading: true,
    isInitializing: true,
    purchases: [],
  }));

  useAsyncEffect(async () => {
    const productPurchases = await rpx.accounts.getMyProductPurchases({ cursor: 0 });
    setState({
      isInitializing: false,
      isLoading: false,
      ...productPurchases,
    });
  }, []);

  const loadMore = async () => {
    try {
      setState((s) => ({ ...s, isLoading: true }));
      const result = await rpx.accounts.getMyProductPurchases({ cursor: state.cursor || 0 });
      setState((s) => ({
        ...s,
        cursor: result.cursor,
        purchases: [...s.purchases, ...result.purchases],
      }));
    } catch (err) {
      showError(err);
    } finally {
      setState((s) => ({ ...s, isLoading: false }));
    }
  };

  if (state.isInitializing) {
    return (
      <div class="p-16">
        <DefaultSpinner />
      </div>
    );
  }

  return (
    <div class="flex flex-col gap-4 py-8">
      {courseId && <CoursePurchaseDetails courseId={courseId} />}
      {coreBilling && <RuzukuBillingDetails />}
      {displayBilling && <RuzukuBillingItem />}

      {state.purchases.length > 0 && (
        <>
          <h2 class="px-2 font-semibold">{intl('Your Purchases')}</h2>
          {state.purchases.map((x) => (
            <CoursePurchaseItem {...x} key={x.purchase.id} />
          ))}
          {!!state.cursor && (
            <BtnSecondary isLoading={state.isLoading} onClick={loadMore}>
              Load More
            </BtnSecondary>
          )}
        </>
      )}
    </div>
  );
}

function RuzukuBillingDetails() {
  const user = useCurrentUser();

  return (
    <SlideOver close={() => router.goto(location.pathname)}>
      {user?.tier === 'free' && <CorePricingTable />}
      {user?.tier !== 'free' && <NonFreeContent />}
    </SlideOver>
  );
}

function NonFreeContent() {
  const { isLoading, data } = useAsyncData(() => rpx.myCorePayments.getCorePurchases(), []);
  const [showPricing, setShowPricing] = useState(false);

  if (isLoading || !data) {
    return <DefaultSpinner />;
  }

  const { isBilledExternally, corePurchase, instantCoursePurchase, account } = data;
  const isLifetime = account.isLifetime || !!data.corePurchase?.price?.metadata?.isLifetime;

  if (isBilledExternally || (!corePurchase && !instantCoursePurchase)) {
    return (
      <AccountTabContent title="Ruzuku Billing">
        <p class="mb-8">
          Contact <a href="mailto:billing@ruzuku.com">billing@ruzuku.com</a> with any questions
          about your account.
        </p>
        {!isBilledExternally && !isLifetime && <CorePricingTable />}
      </AccountTabContent>
    );
  }

  return (
    <AccountTabContent title="Ruzuku Billing">
      {showPricing && (
        <div class="my-8">
          <CorePricingTable />
        </div>
      )}
      <PurchasePane
        {...corePurchase}
        allowCancel={!isLifetime && corePurchase?.price?.paymentType !== 'paymentplan'}
        changePlan={isLifetime ? undefined : () => setShowPricing(true)}
        changeMessage={
          isLifetime && (
            <span>
              Contact{' '}
              <a href="mailto:billing@ruzuku.com" class="text-indigo-600 underline">
                billing
              </a>{' '}
              to change your Ruzuku plan.
            </span>
          )
        }
        asSeller={false}
      />
      {corePurchase && instantCoursePurchase && <hr class="my-8" />}
      <PurchasePane
        {...instantCoursePurchase}
        allowCancel={instantCoursePurchase?.price?.paymentType !== 'paymentplan'}
        changePlan={() => setShowPricing(true)}
        asSeller={false}
      />
    </AccountTabContent>
  );
}
