import { ComponentChildren } from 'preact';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { Dropdown, MenuItem } from '@components/dropdown';
import {
  IcoBook,
  IcoChevronLeft,
  IcoDownload,
  IcoFolder,
  IcoGift,
  IcoLibrary,
  IcoMail,
} from '@components/icons';
import { hasLevel } from 'shared/auth';
import { UserMenu } from '@components/nav/user-menu';
import { useDocumentTitle } from 'client/utils/use-document-title';

type Page =
  | 'settings'
  | 'branding'
  | 'people'
  | 'courses'
  | 'fields'
  | 'terminology'
  | 'instantCourses'
  | 'prices'
  | 'coupons'
  | 'tenants'
  | 'storefront'
  | 'invitations'
  | 'gifts';

function AdminLink({
  href,
  children,
  isSelected,
}: {
  children: ComponentChildren;
  href: string;
  isSelected: boolean;
}) {
  return (
    <a
      href={href}
      class={`border-b-2 py-1 -mb-px ${isSelected ? 'border-indigo-600' : 'border-transparent'}`}
    >
      {children}
    </a>
  );
}

export function AdminNav({ currentPage, pageTitle }: { currentPage: Page; pageTitle?: string }) {
  const tenant = useCurrentTenant();
  const user = useCurrentUser();

  useDocumentTitle([pageTitle, 'Manage Site']);

  return (
    <header>
      <nav class="p-4 flex items-center justify-between">
        <a class="inline-flex items-center gap-2 text-lg font-medium text-gray-900" href="/">
          <IcoChevronLeft />
          Manage Site
        </a>
        <UserMenu />
      </nav>
      <nav class="px-8 border-b flex items-end justify-center gap-8 font-medium">
        <AdminLink href="/admin/people" isSelected={currentPage === 'people'}>
          People
        </AdminLink>

        <AdminLink href="/admin/courses" isSelected={currentPage === 'courses'}>
          Courses
        </AdminLink>

        <AdminLink href="/admin/fields" isSelected={currentPage === 'fields'}>
          Profile Fields
        </AdminLink>

        {!tenant.isCore && (
          <AdminLink href="/admin/branding" isSelected={currentPage === 'branding'}>
            Branding
          </AdminLink>
        )}

        {tenant.locale === 'en' && (
          <AdminLink href="/admin/terminology" isSelected={currentPage === 'terminology'}>
            Custom Terminology
          </AdminLink>
        )}

        <AdminLink href="/admin/store" isSelected={currentPage === 'storefront'}>
          Storefront
        </AdminLink>

        <AdminLink href="/admin/settings" isSelected={currentPage === 'settings'}>
          Settings
        </AdminLink>

        {hasLevel(user, 'superadmin') && (
          <Dropdown
            renderMenu={() => (
              <div class="p-2 space-y-2 flex flex-col">
                <MenuItem href="/admin/pricing/prod-ruzuku" data-noroute>
                  <IcoFolder />
                  <span class="ml-3">Ruzuku Pricing</span>
                </MenuItem>
                <MenuItem href="/admin/pricing/prod-bundles" data-noroute>
                  <IcoFolder />
                  <span class="ml-3">Instant Courses Pricing</span>
                </MenuItem>
                <MenuItem href="/admin/tenants">
                  <IcoLibrary />
                  <span class="ml-3">Tenants</span>
                </MenuItem>
                <MenuItem href="/csv/upsell-bounces.csv" download="upsell-bounces.csv">
                  <IcoDownload /> <span class="ml-3">Upsell Bounces CSV</span>
                </MenuItem>
                <MenuItem href="/csv/customers.csv" download="customers.csv">
                  <IcoDownload /> <span class="ml-3">Customers CSV</span>
                </MenuItem>
                <MenuItem href="/admin/instant-courses">
                  <IcoBook /> <span class="ml-3">Instant Courses</span>
                </MenuItem>
                <MenuItem href="/admin/invitations">
                  <IcoMail /> <span class="ml-3">Invitations</span>
                </MenuItem>
                <MenuItem href="/admin/gifts">
                  <IcoGift /> <span class="ml-3">Gifts</span>
                </MenuItem>
              </div>
            )}
            class="items-center"
            triggerClass="text-indigo-600 font-medium py-1"
          >
            Manage Ruzuku
          </Dropdown>
        )}
      </nav>
    </header>
  );
}

export function AdminTabWrapper(props: { children: ComponentChildren }) {
  return <div class="p-8 w-5xl max-w-full mx-auto">{props.children}</div>;
}

export function AdminTabHeader(props: { title: ComponentChildren; children?: ComponentChildren }) {
  return (
    <header class="flex items-center justify-between gap-8 mb-8">
      <h2 class="text-2xl">{props.title}</h2>
      {props.children}
    </header>
  );
}
