import { BtnPrimary, Button } from '@components/buttons';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { useAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { ComponentChildren } from 'preact';

function Banner(props: {
  title: string;
  children: ComponentChildren;
  actionURL: string;
  actionText: string;
}) {
  return (
    <div class="mb-8 p-8 flex flex-col gap-4 bg-gradient-to-b from-gray-100 to-indigo-50 rounded-2xl">
      <h2 class="text-lg font-semibold leading-none">{props.title}</h2>
      <p>{props.children}</p>
      <footer>
        <BtnPrimary href={props.actionURL}>{props.actionText}</BtnPrimary>
      </footer>
    </div>
  );
}

function LoadTenantBanner() {
  const { data: myTenant, isLoading } = useAsyncData(async () => rpx.tenants.getMyTenant(), []);

  if (isLoading) {
    return null;
  }

  const manageURL = myTenant?.domain ? `https://${myTenant.domain}` : '/account/site';

  if (myTenant?.domain) {
    return (
      <Banner
        title="Are you trying to manage courses for your Ruzuku Pro site?"
        actionURL={manageURL}
        actionText="Go to your Ruzuku Pro site ➜"
      >
        You have a Ruzuku Pro site which can be viewed and managed at{' '}
        <a href={manageURL}>{manageURL}.</a>
      </Banner>
    );
  }

  return (
    <Banner
      title="Welcome to Ruzuku Pro!"
      actionURL={manageURL}
      actionText="Set up your Ruzuku Pro site ➜"
    >
      Now that you have a Ruzuku Pro account, you should set up your custom domain in{' '}
      <a href={manageURL}>account settings</a>. Check out{' '}
      <Button href="https://support.ruzuku.com/article/820-setting-up-your-pro-custom-domain">
        the help documentation
      </Button>{' '}
      for more information, or <a href="mailto:support@ruzuku.com">contact us</a> if you need help
      getting started.
    </Banner>
  );
}

/**
 * Banner to remind pro-tier guides they should configure their subdomain
 * or manage their tenant on their own domain.
 */
export function ProTierBanner() {
  const tenant = useCurrentTenant();
  const user = useCurrentUser()!;

  if (
    !tenant.isCore ||
    user.tier !== 'pro' ||
    user.level === 'admin' ||
    user.level === 'superadmin'
  ) {
    return null;
  }

  return <LoadTenantBanner />;
}
