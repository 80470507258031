import { RouteProps, router } from '@components/router';
import { RuzcalMgmtPage } from './mgmt-page';
import { IcoClock, IcoExternalLink } from '@components/icons';
import { ListAddAction, ListContainer, PageContent, PageHeading, PageSection } from './common';
import { rpx } from 'client/lib/rpx-client';
import { EventTypeRow } from 'server/types/cal-schema';
import { BtnCopy, Button } from '@components/buttons';
import { ruzcal } from 'shared/urls';

async function load() {
  const [meetingTypes, availability, host] = await Promise.all([
    rpx.ruzcal.getEventTypesList(),
    rpx.ruzcal.getAvailabilityList(),
    rpx.ruzcal.getHost({}),
  ]);
  return { meetingTypes, availability, urlPrefix: host.urlPrefix };
}

type Data = Awaited<ReturnType<typeof load>>;

function summarizeDuration(duration: number) {
  return `${duration}m`;
}

function EditLink(
  props: Pick<
    EventTypeRow,
    'id' | 'name' | 'isPrivate' | 'duration' | 'location' | 'locationDetail'
  > & { href: string } & {
    availability?: Data['availability'][0];
  },
) {
  const fullUrl = `${window.location.origin}${props.href}`;

  return (
    <a
      href={`/ruzcal-mgmt/meeting-types/${props.id}`}
      class="flex flex-col rounded-md p-4 hover:bg-gray-100 transition-all text-inherit"
    >
      <span class="flex justify-between items-center gap-4">
        <span class="font-semibold">{props.name}</span>
        <span
          class="flex -mr-3 p-1"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <BtnCopy
            value={fullUrl}
            copiedText="Link copied"
            class="hover:bg-gray-700 hover:text-white transition-all rounded-full aspect-square p-1 w-8 h-8"
            iconOnly
          />
          <Button
            href={fullUrl}
            target="_blank"
            class="hover:bg-gray-700 hover:text-white transition-all rounded-full aspect-square p-1 text-inherit inline-flex items-center justify-center w-8 h-8"
            onClick={(e) => e.stopPropagation()}
          >
            <IcoExternalLink />
          </Button>
        </span>
      </span>
      <span class="flex flex-col-reverse sm:flex-row justify-between">
        <span class="opacity-75">{props.href}</span>
        <span class="flex flex-row-reverse sm:flex-row items-start justify-end gap-4">
          {props.isPrivate && (
            <span>
              <span class="bg-gray-50 border border-gray-300 rounded px-1 p-0.5 text-xs">
                Hidden
              </span>
            </span>
          )}
          {props.location && <span class="opacity-75">{props.location}</span>}
          {props.availability && <span class="opacity-75">{props.availability.name}</span>}
          <span class="inline-flex items-center gap-1">
            <IcoClock /> {summarizeDuration(props.duration)}
          </span>
        </span>
      </span>
    </a>
  );
}

function Page(props: RouteProps<Data>) {
  const { meetingTypes, availability, urlPrefix } = props.state;
  return (
    <RuzcalMgmtPage title="Meeting types" currentPage="meetingtypes">
      <PageContent>
        <PageSection>
          <PageHeading
            title="Meeting types"
            subtitle="Manage the types of meetings that people can book."
            action={
              <ListAddAction href="/ruzcal-mgmt/meeting-types/new" content="Add meeting type" />
            }
          />
          {meetingTypes.length > 0 && (
            <ListContainer>
              {meetingTypes.map((x) => (
                <EditLink
                  key={x.id}
                  id={x.id}
                  name={x.name}
                  href={ruzcal.newBookingUrl({ urlPrefix, urlSuffix: x.urlSuffix })}
                  duration={x.duration}
                  location={x.location}
                  locationDetail={x.locationDetail}
                  isPrivate={x.isPrivate}
                  availability={availability.find((a) => a.id === x.availabilityId)}
                />
              ))}
            </ListContainer>
          )}
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}

router.add({
  url: 'ruzcal-mgmt/meeting-types',
  authLevel: 'superadmin',
  load,
  render: Page,
});
