/**
 * This file contains logic for displaying a modal form whose submitted value
 * can be awaited.
 */

import { ComponentChildren, createContext, JSX } from 'preact';
import { useContext, useState } from 'preact/hooks';
import { AutoForm, Context, useAutoFormState } from '@components/async-form';
import { FileRec } from 'server/types';
import { AttachmentItem } from '@components/attachments/attachment-item';
import { DialogFooter, showGlobal, StandardDialog } from '@components/dialog';

type Resolvable = { resolve(data?: any): void };
type ModalProps = undefined | ((props: Resolvable) => JSX.Element | null);

type Props<T = any> = {
  title: ComponentChildren;
  subtitle?: ComponentChildren;
  confirmButtonText?: JSX.Element | string;
  cancelButtonText?: JSX.Element | string;
  mode?: 'warn';
  hideCancel?: boolean;
  autoFocus?: boolean;
  contentWidth?: boolean;
  onClose?(): void;
  onSubmit?(val?: any): Promise<unknown>;
  children?: ComponentChildren;
  ctx?: Context<T>;
};

export const ModalFormContext = createContext<{ resolve(data: any): void }>({ resolve() {} });

/**
 * Show a form as a modal. This does not submit to the server,
 * but instead resolves to an object that is the result of
 * serializing the form.
 */
export async function showModalForm<T>(Render: ModalProps) {
  if (!Render) {
    return undefined;
  }

  return showGlobal<T>((props) => {
    return (
      <ModalFormContext.Provider value={props}>
        <Render {...props} />
      </ModalFormContext.Provider>
    );
  });
}

/*
 * Show the attachment in the document root.
 */
export async function showAttachmentModal(opts: { attachment: FileRec }) {
  const result = await showModalForm(() => <AttachmentModal attachment={opts.attachment} />);
  return !!result;
}

function AttachmentModal(props: { attachment: FileRec; hideCancel?: boolean }) {
  const { resolve } = useContext(ModalFormContext);
  const hide = () => resolve(undefined);

  return (
    <StandardDialog onClose={hide}>
      <div class="flex justify-center min-w-full max-w-full h-full rounded overflow-hidden py-8">
        <AttachmentItem
          class="h-full"
          attachment={props.attachment}
          fullSize
          allowFullScreen={false}
        />
      </div>
    </StandardDialog>
  );
}

function ContextedModalForm<T = any>(
  props: Omit<Props<T>, 'ctx'> & {
    ctx: Context<T>;
  },
) {
  const { resolve } = useContext(ModalFormContext);
  const [isLoading, setIsLoading] = useState(false);
  const hide = () => {
    resolve?.(undefined);
    props.onClose?.();
  };
  return (
    <StandardDialog
      onClose={hide}
      contentWidth={props.contentWidth}
      title={props.title}
      subtitle={props.subtitle}
    >
      <AutoForm
        ctx={props.ctx}
        autoFocus={props.autoFocus !== false}
        class="flex flex-col gap-6"
        onSubmit={async (data) => {
          try {
            setIsLoading(true);

            if (data === undefined || !props.onSubmit) {
              return resolve(data);
            } else {
              return resolve(await props.onSubmit(data));
            }
          } finally {
            setIsLoading(false);
          }
        }}
      >
        {props.children}
        <DialogFooter {...props} onClose={hide} isLoading={isLoading} />
      </AutoForm>
    </StandardDialog>
  );
}

function AutoModalForm<T = any>(props: Props<T>) {
  const [, ctx] = useAutoFormState({ initialState: {} });
  return <ContextedModalForm {...props} ctx={ctx} />;
}

export function ModalForm<T = any>(props: Props<T>) {
  if (props.ctx) {
    return <ContextedModalForm {...props} ctx={props.ctx!} />;
  }
  return <AutoModalForm {...props} />;
}
