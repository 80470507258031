import { RouteProps, router } from '@components/router';
import { FixedContent, FixedPage } from '@components/fixed-page';
import { AdminNav, AdminTabWrapper } from '@components/admin-nav';
import { AppRoute } from 'client/lib/app-route/types';
import { UserProfileIcon } from '@components/avatars';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { hasLevel } from 'shared/auth';
import { BtnCopy, BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { showError } from '@components/app-error';
import { toQueryString } from 'shared/urls';
import { Pill } from '@components/pill';
import { describeAccessFormat } from 'client/utils/courses';
import { IcoCheckCircle, IcoMail, IcoUserGroup } from '@components/icons';
import { pluralize } from 'shared/formatting';
import { fmtFullDate } from 'shared/dateutil';
import { truncateId } from 'shared/utils';
import { UserLevel } from 'server/types';
import { showCourseCopyModal } from './course-copy-modal';
import { Toggle } from '@components/toggle';
import { rpx } from 'client/lib/rpx-client';
import { showEnrollAStudentModal } from './enroll-student-modal';
import { useState } from 'preact/hooks';
import { InvitationModal } from '../guide-course-invitations/invitation-modal';
import { showToast } from '@components/toaster';
import { StudentsTable } from './students-table';
import { CourseAuditLogsSlider } from './course-detail-audit-logs';
import { BulkEnrollStudentsModal } from './bulk-enroll-students-modal';
import { showDialog } from '@components/dialog';

async function load(route: AppRoute) {
  const { courseId } = route.params;
  const { courses } = await rpx.tenants.getTenantCourses({ courseId });

  return {
    course: courses[0],
  };
}

type Data = Awaited<ReturnType<typeof load>>;

const instantCoursesStore = rpx.instantCourses;

function Page({ data, route: { params } }: RouteProps<Data>) {
  const course = data.course;
  const guide = course.guide;
  const currentUser = useCurrentUser();
  const tenant = useCurrentTenant();
  const isSuperAdmin = currentUser?.level === 'superadmin';
  const [modal, setModal] = useState<'invite' | 'bulkEnroll' | undefined>(undefined);

  // Toggle a slideover for course audit logs, for superadmin
  const [showCourseLogs, setShowCourseLogs] = useState(false);

  const mimicAndView = async (user: { id: UUID; level: UserLevel }) => {
    const canMimic = hasLevel(currentUser, user.level);
    if (!canMimic) {
      return;
    }
    try {
      await rpx.admin.mimicUser({ userId: user.id });
      location.assign(`/courses/${course.id}`);
    } catch (err) {
      showError(err);
    }
  };

  return (
    <FixedPage>
      {modal === 'bulkEnroll' && (
        <BulkEnrollStudentsModal
          course={course}
          onCancel={() => setModal(undefined)}
          onSent={async (result) => {
            setModal(undefined);

            let message = `${result.enrolledUsers} new students are added to the course.`;
            if (result.invitedUsers) {
              message += ` We sent invitations to ${result.invitedUsers} email addresses because they don't have an account yet.`;
            }
            if (result.alreadyEnrolledUsers) {
              message += ` ${result.alreadyEnrolledUsers} email addresses were already enrolled in the course so they are ignored.`;
            }

            await showDialog({
              title: 'Students added',
              children: message,
              mode: 'ok',
              hideCancel: true,
              confirmButtonText: 'Reload the course',
            });
            // Reload the page to update the student list
            location.reload();
          }}
        />
      )}
      {modal === 'invite' && (
        <InvitationModal
          courseId={course.id}
          onCancel={() => setModal(undefined)}
          hideModifyText
          send={async (data) => {
            const result = await rpx.admin.bulkInviteStudents({
              courseId: course.id,
              emails: data.emails,
              accessDuration: data.accessDuration,
            });
            return result;
          }}
          onSent={({ invitations }) => {
            showToast({
              type: 'ok',
              title: 'Invitations sent',
              message: `${invitations.length} invitations sent!`,
            });
            setModal(undefined);
          }}
        />
      )}
      <FixedContent class="bg-white">
        <AdminNav currentPage="courses" pageTitle="Courses" />
        <AdminTabWrapper>
          <header class="flex justify-between mb-6">
            <div class="flex flex-col flex-grow">
              <h2 class="text-2xl mb-2">
                <a href={`/admin/courses?${toQueryString({ q: params.q })}`}>Courses</a>
                {' / '}
                {course.title}
              </h2>
              {isSuperAdmin && showCourseLogs && (
                <CourseAuditLogsSlider closeSlider={() => setShowCourseLogs(false)} />
              )}
              <div class="flex flex-wrap items-center text-sm gap-6">
                {!tenant.isCore && (
                  <span>
                    <span class="opacity-60 mr-2">Brand:</span>
                    <span>{course.brandName || 'Default Brand'}</span>
                  </span>
                )}

                <span class="inline-flex items-center">
                  <span class="opacity-60 mr-2">Course ID:</span>
                  <span>{course.id}</span>
                  <BtnCopy value={course.id}>Copy ID</BtnCopy>
                </span>
              </div>
            </div>
          </header>
          <nav class="flex space-x-4">
            <BtnSecondary onClick={() => showEnrollAStudentModal(course)}>
              Add a Student
            </BtnSecondary>
            <BtnSecondary onClick={() => setModal('bulkEnroll')}>Add Students in Bulk</BtnSecondary>
            <BtnSecondary onClick={() => setModal('invite')}>Bulk Invite Students</BtnSecondary>
            {isSuperAdmin && (
              <BtnSecondary onClick={() => setShowCourseLogs(true)}>View logs</BtnSecondary>
            )}
            <BtnPrimary onClick={() => showCourseCopyModal(course)}>
              Copy {course.isBundle ? 'Bundle' : 'Course'}
            </BtnPrimary>
            {hasLevel(currentUser, 'superadmin') && (
              <label class="inline-flex items-center space-x-3 cursor-pointer pl-4 border-l">
                <Toggle
                  checked={course.isTemplate}
                  onClick={async () => {
                    try {
                      await instantCoursesStore.toggleCourseTemplate({
                        id: course.id,
                        isTemplate: !course.isTemplate,
                      });
                    } catch (err) {
                      showError(err);
                    }
                  }}
                />
                <span>Is Template</span>
              </label>
            )}
          </nav>
          <div class="flex items-center mb-6 space-x-8 text-sm border-t border-b border-dashed py-4 my-6">
            <span class="inline-flex items-center">
              <IcoUserGroup class="w-4 h-4 opacity-50 mr-2" />
              {course.numStudents || 0} {pluralize('student', course.numStudents || 0)}
            </span>
            <span class="inline-flex items-center">
              <IcoMail class="w-4 h-4 opacity-50 mr-2" />
              {course.pendingInvitations || 0} pending{' '}
              {pluralize('invitation', course.pendingInvitations || 0)}
            </span>
            <span class="inline-flex items-center gap-1">
              {course.isProduct && <Pill color="blue">Product</Pill>}
              {course.isBundle && <Pill color="yellow">Bundle</Pill>}
              {course.status === 'draft' && <Pill color="gray">Draft</Pill>}
              {course.status === 'published' && <Pill color="green">Published</Pill>}
            </span>
            <span class="inline-flex items-center">
              {describeAccessFormat(course.accessFormat)}
            </span>
            <span class="inline-flex items-center">
              <span class="opacity-60 mr-1.5">Created</span> {fmtFullDate(course.createdAt)}
            </span>
          </div>
          <div class="text-gray-500 text-sm">
            <h3 class="block text-gray-500 font-bold mb-2">Guide</h3>
            <span class="relative flex border rounded mb-6">
              <a
                class="grid grid-cols-2 flex-grow text-inherit text-sm p-4"
                href={`/admin/people/${guide.id}`}
              >
                <span class="flex items-center">
                  <UserProfileIcon user={guide} size="w-10 h-10" />
                  <span class="flex flex-col ml-3">
                    <strong>{guide.name}</strong>
                    <span data-private>{guide.email}</span>
                  </span>
                </span>
                <span class="flex flex-col">
                  <span class="flex items-center text-green-600">
                    <IcoCheckCircle class="w-3 h-3 mr-1 opacity-75 text-white bg-green-600 border-green-600 rounded-full" />
                    Guide
                  </span>
                  <span>ID: {truncateId(guide.id)}</span>
                </span>
              </a>
              <Button
                class="group flex items-center p-4"
                disabled={!hasLevel(currentUser, guide.level)}
                onClick={() => mimicAndView(guide)}
              >
                <span class="inline-block -mt-0.5 px-2 text-sm py-1 bg-gray-50 border group-hover:bg-indigo-600 group-hover:text-white group-hover:border-indigo-600 rounded whitespace-nowrap">
                  Mimic &amp; view
                </span>
              </Button>
            </span>
            <h3 class="block text-gray-500 font-bold mb-2">Students</h3>
            <StudentsTable courseId={course.id} />
          </div>
        </AdminTabWrapper>
      </FixedContent>
    </FixedPage>
  );
}

router.add({
  load,
  authLevel: 'admin',
  url: '/admin/courses/:courseId',
  render: Page,
});
