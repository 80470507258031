import { Button } from '@components/buttons';
import { useRouteData } from '@components/router';
import { PriceRow } from 'server/types';
import { PriceSummary } from '../pmts/components/price-summary';
import { DialogFooter, StandardDialog } from '@components/dialog';
import { load, showNewPriceModal } from './guide-course-salespage';
import { showModalForm } from '@components/modal-form';
import { LoadedProps } from 'client/lib/loaders';

interface Props {
  hide(): void;
  onApply(p: PriceRow): void;
}

function PriceModal(props: Props) {
  const pageData = useRouteData<LoadedProps<typeof load>['data']>();
  return (
    <StandardDialog onClose={props.hide} title="Choose a price point">
      <header>
        <Button
          class="flex flex-col bg-gray-100 hover:bg-gray-200 cursor-pointer text-left p-2 px-4 font-medium rounded-full"
          onClick={() =>
            showNewPriceModal({
              courseId: pageData.course.id,
              productId: pageData.product.id,
              supportsStripe: pageData.supportsStripe,
              supportsPaypal: pageData.supportsPaypal,
              setPrice(price) {
                // This is a hack to prevent having to refresh the page
                pageData.prices.push(price);
                props.onApply(price);
              },
            })
          }
        >
          Create new price point
        </Button>
      </header>
      <div class="flex flex-col border rounded-lg overflow-hidden divide-y">
        {pageData.prices.map((p) => (
          <Button
            key={p.id}
            onClick={() => {
              props.onApply(p);
              props.hide();
            }}
            class="flex flex-col hover:bg-gray-100 text-sm cursor-pointer text-left p-4"
          >
            <strong>{p.name}</strong>
            <PriceSummary price={p} />
          </Button>
        ))}
      </div>
      <DialogFooter hideConfirm onClose={props.hide} />
    </StandardDialog>
  );
}

export function showPriceModal() {
  return showModalForm<PriceRow>(({ resolve }) => <PriceModal hide={resolve} onApply={resolve} />);
}
