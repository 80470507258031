import { RouteProps, router } from '@components/router';
import { FixedContent, FixedPage } from '@components/fixed-page';
import { AdminNav, AdminTabHeader, AdminTabWrapper } from '@components/admin-nav';
import { TenantCoursesList } from './course-list';
import './course-detail';
import { load, Data } from './load';
import { DownloadCsvButton } from '@components/download-csv-modal';

function Page({ data }: RouteProps<Data>) {
  return (
    <FixedPage>
      <FixedContent class="bg-white">
        <AdminNav currentPage="courses" pageTitle="Courses" />
        <AdminTabWrapper>
          <AdminTabHeader title="Courses">
            <DownloadCsvButton type="tenant" />
          </AdminTabHeader>
          <TenantCoursesList type="all" dontRewriteUrl {...data} />
        </AdminTabWrapper>
      </FixedContent>
    </FixedPage>
  );
}

router.add({
  load,
  authLevel: 'admin',
  url: '/admin/courses',
  render: Page,
});
