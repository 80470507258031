import { rpx } from 'client/lib/rpx-client';
import { normalizedStatus } from './utils';
import { AppRoute } from 'client/lib/app-route/types';
import { AccessFormat } from 'server/types';

export async function load(route: AppRoute) {
  const [courses, crossTenant] = await Promise.all([
    rpx.courses.getMyCourses({}),
    rpx.courses.getTenantCourseCounts(),
  ]);

  return {
    crossTenant,
    courses: courses.map((c) => ({
      ...c,
      // If the current user is not the guide, the archived status of the course
      // is irrellevant, so we'll set it to false. This way, the course shows
      // up for students regardless of the guide's setting it to isArchived.
      isArchived: c.isArchived && c.guide.id === route.auth.user?.id,
      normalizedStatus: normalizedStatus(c),
      search: `${c.title.toLocaleLowerCase()} ${c.guide.name?.toLocaleLowerCase()} ${c.id}`,
      numStudents: c.numStudents || 0,
    })),
  };
}

export type Data = Awaited<ReturnType<typeof load>>;
export type Course = Data['courses'][0];
export type CourseType = 'courses' | 'bundles' | 'products';
export type NormalizedStatus = 'draft' | 'published' | 'closed';

export interface Filters {
  show: 'all' | 'teaching' | 'attending' | 'archived';
  sort: 'lastOpenedAt' | 'createdAt' | 'title' | 'numStudents';
  sortDirection: number;
  status: 'all' | NormalizedStatus;
  type: 'all' | AccessFormat;
}
