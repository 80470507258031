import { router } from '@components/router';
import { RuzcalMgmtPage } from './mgmt-page';
import dayjs from 'dayjs';
import { useEffect } from 'preact/hooks';
import { IcoCalendar, IcoMapPin, IcoVideoCamera } from '@components/icons';
import { ListContainer, PageContent, PageHeading, PageSection } from './common';
import { rpx } from 'client/lib/rpx-client';
import { LoadedProps } from 'client/lib/loaders';
import { isNowish } from './dateutil';
import { Button } from '@components/buttons';
import { AppRoute, RouteParams } from 'client/lib/app-route/types';
import { ruzcal } from 'shared/urls';

const loadEvents: typeof rpx.ruzcal.getMyEvents = async (opts) => {
  const result = await rpx.ruzcal.getMyEvents(opts);
  return {
    ...result,
    events: result.events.map((e) => ({
      ...e,
      start: new Date(e.start),
      end: new Date(e.end),
    })),
  };
};

async function load(route: Pick<AppRoute, 'params'>) {
  const isPast = !!route.params.past;
  const today = dayjs().startOf('day').toISOString();
  const [{ events, hasMore, cursor }] = await Promise.all([
    loadEvents({
      direction: isPast ? 'DESC' : 'ASC',
      today,
    }),
    rpx.ruzcal.ensureHost(),
  ]);
  return { events, hasMore, isPast, cursor, today };
}

type Props = LoadedProps<typeof load>;
type State = Props['state'];

function EditLink(
  props: State['events'][0] & {
    isNow: boolean;
    isNext: boolean;
    isPast: boolean;
  },
) {
  const [attendee] = props.attendees;
  const start = dayjs(props.start);
  return (
    <a
      href={ruzcal.existingBookingUrl({ id: props.id })}
      class={`relative flex flex-col gap-4 transition-all p-3 rounded-md font-medium ${
        props.isNow
          ? 'bg-green-50 ring-2 ring-green-400 text-green-700 hover:bg-green-100 hover:text-green-800'
          : props.isNext
          ? 'bg-sky-50 text-sky-700 hover:bg-sky-100 hover:text-sky-800 mt-4 ring-1 ring-sky-200'
          : props.isPast
          ? 'opacity-75 text-inherit hover:bg-gray-100 hover:opacity-100'
          : 'text-inherit hover:bg-gray-100'
      }`}
    >
      <span class="flex justify-between items-center gap-4">
        <span class="size-8 text-xs font-semibold bg-white border border-t-8 border-sky-300 inline-flex items-center justify-center rounded-md">
          {start.date()}
        </span>
        <span class="flex flex-col sm:flex-row sm:gap-4 items-center grow">
          <span class="flex items-center gap-2">
            <span class="flex justify-between items-center w-32">
              <span>{start.format('h:mma')}</span>
              {' - '}
              <span class="opacity-75">{dayjs(props.end).format('h:mma')}</span>
            </span>
          </span>
          <span>
            <span class="opacity-75">{props.name} with</span> <span>{attendee.name}</span>
          </span>
        </span>
        <span class="flex flex-col sm:flex-row items-center sm:gap-4 text-xs">
          {props.isNow && (
            <span class="bg-green-500 text-white text-xs font-semibold px-1.5 rounded rounded-bl-none absolute -top-3 left-0 ring-2 ring-green-500 flex items-center gap-2">
              <span class="relative size-2 bg-green-100 rounded-full inline-flex">
                <span class="absolute inset-0 bg-green-100 rounded-full inline-flex animate-ping"></span>
              </span>
              <span class="relative">Now</span>
            </span>
          )}
          {props.isNext && (
            <span class="bg-sky-500 text-white text-xs font-semibold px-1.5 rounded rounded-bl-none absolute -top-3 left-0 ring-2 ring-sky-500 flex items-center gap-2">
              <span class="relative">Up next</span>
            </span>
          )}
          <span class="md:flex items-center gap-2 hidden">{props.duration} mins</span>
          <span class="flex items-center gap-1 capitalize">
            {props.location === 'external' ? <IcoMapPin /> : <IcoVideoCamera />}
            {props.location}
          </span>
        </span>
      </span>
    </a>
  );
}

function BookingList(props: { events: State['events'] }) {
  const now = dayjs();
  let hadNext = false;

  return (
    <div class="flex flex-col gap-4">
      {props.events.map((b, i) => {
        const prev = props.events[i - 1]?.start;
        const isNow = isNowish(b);
        const isPast = !isNow && now.isAfter(b.start);
        const isNear = Math.abs(now.diff(b.start, 'd')) < 6;
        const isNext =
          !hadNext && !isNow && b.start.getDay() === now.day() && now.isBefore(b.start);
        const isNewMonth =
          !prev ||
          b.start.getFullYear() !== prev.getFullYear() ||
          b.start.getMonth() !== prev.getMonth();
        const isNewDay = isNewMonth || !prev || prev.getDate() !== b.start.getDate();
        hadNext = true;
        return (
          <>
            {isNewMonth && (
              <span class="font-semibold text-sky-600 border-b border-dashed border-sky-600 p-2 px-3">
                {dayjs(b.start).format('MMMM YYYY')}
              </span>
            )}
            {isNewDay && isNear && (
              <span class="flex px-3">{dayjs(b.start).format('dddd, MMM D')}</span>
            )}
            <EditLink key={b.start} {...b} isNext={isNext} isNow={isNow} isPast={isPast} />
          </>
        );
      })}
    </div>
  );
}

function HeaderLink(props: { href: string; children: string; isSelected?: boolean }) {
  return (
    <Button
      href={props.href}
      class={`p-1 px-2 hover:shadow hover:bg-gray-200 transition-all rounded-full ${
        props.isSelected ? 'bg-gray-200 text-black' : 'text-inherit'
      }`}
    >
      {props.children}
    </Button>
  );
}

function Page({ state, setState }: Props) {
  useEffect(() => {
    /**
     * Redraw every 60 seconds to shift the live view, etc.
     */
    const interval = 60000;
    let timeout = setTimeout(function tick() {
      setState((s) => s);
      timeout = setTimeout(tick, interval);
    }, interval);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <RuzcalMgmtPage title="Bookings" currentPage="bookings">
      <PageContent>
        <PageSection>
          <PageHeading
            title="Bookings"
            subtitle="Manage your upcoming bookings and view booking history."
          />
          <nav class="flex gap-4 font-medium mb-4">
            <HeaderLink href="/ruzcal-mgmt/bookings" isSelected={!state.isPast}>
              Current & upcoming
            </HeaderLink>
            <HeaderLink href="/ruzcal-mgmt/bookings?past=true" isSelected={state.isPast}>
              Past bookings
            </HeaderLink>
          </nav>
          {state.events.length === 0 && (
            <div class="flex items-center gap-4 text-gray-500 bg-white rounded-2xl p-4 shadow">
              <IcoCalendar class="size-8 shrink-0" />
              <p>No bookings found</p>
            </div>
          )}
          {state.events.length > 0 && (
            <ListContainer>
              <BookingList events={state.events} />
              {state.hasMore && (
                <Button
                  class="flex p-2 justify-center hover:bg-gray-100 rounded-lg"
                  onClick={async () => {
                    const result = await loadEvents({
                      cursor: state.cursor,
                      today: state.today,
                      direction: state.isPast ? 'DESC' : 'ASC',
                    });
                    setState((s) => ({
                      ...s,
                      events: [...s.events, ...result.events],
                      hasMore: result.hasMore,
                      cursor: result.cursor,
                    }));
                  }}
                >
                  Load more
                </Button>
              )}
            </ListContainer>
          )}
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}

async function loadSubroute(params: RouteParams, setState: Props['setState']) {
  const result = await load({ params });
  setState((s: State) => ({ ...s, ...result }));
}

router.add({
  url: 'ruzcal-mgmt',
  authLevel: 'superadmin',
  load,
  loadSubroute,
  render: Page,
});

router.add({
  url: 'ruzcal-mgmt/bookings',
  authLevel: 'superadmin',
  load,
  loadSubroute,
  render: Page,
});
